import React, { useState } from "react";
import { Form, Input, Button, ErrorMsg, Logo } from "../../Components";
import { apis, post } from "../../Utils/axios";
import { useNavigate } from "react-router-dom";
import HappyDog from "../../Assets/Images/happy_dog.jpg";

function Signup() {
	const Params = new URLSearchParams(window.location.search);
	const code = Params.get("code");
	const action = Params.get("action");

	const NAVIGATE = useNavigate();
	const [Values, setValues] = useState({
		password: "",
		confirm: "",
		code,
	});
	const [PasswordChanged, setPasswordChanged] = useState();

	const [Loader, setLoader] = useState();
	const [Error, setError] = useState();

	const updatePassword = (e) => {
		setError();
		setLoader(true);
		if (Values.password !== Values.confirm) {
			setError("Passwords do not match");
			setLoader();
			return;
		}

		//Based on action changing urls
		post(action === "INVITATION" ? apis.verifyInvitation : apis.verifyEmail, { code, password: Values.password, action }, (r, e) => {
			if (r) {
				setPasswordChanged(true);
				setLoader();
			} else if (e) {
				setError(e.response.data.error);
				setLoader();
			}
		});
	};

	if (PasswordChanged) {
		return (
			<div className="justify-center w-full h-full align-center">
				<div className="w-full max-w-md p-4 space-y-10 text-center">
					<Logo className="justify-center m-auto w-28" />
					<img src={HappyDog} alt="Happy dog" className="m-auto mb-8 rounded-full max-w-52" />
					<div className="text-4xl font-bold">Verification Pending</div>
					<div className="text-lg font-light">We have successfully created your account, as part of the verification process it is being verified by our teams, You will receive a confirmation email when your account is ready for use.</div>
				</div>
			</div>
		);
	}

	return (
		<div className="justify-center w-full h-full align-center">
			<div className="w-full max-w-sm p-4 text-center">
				<Logo className="justify-center" />
				<div className="mt-6 text-5xl font-bold">Set Password</div>
				<div className="mt-3 text-sm">Enter a new password for your account</div>
				<div className="flex flex-col mt-5">
					<Form onSubmit={updatePassword} loading={Loader}>
						<Input type="password" className="mb-4" value={Values.password} onChange={(password) => setValues((prev) => ({ ...prev, password }))} required label="New Password" placeholder="Password" />
						<Input type="password" value={Values.confirm} onChange={(confirm) => setValues((prev) => ({ ...prev, confirm }))} required label="Confirm New Password" placeholder="Confirm Password" />
						<ErrorMsg e={Error} />
						<Button loading={Loader} type="submit" className="w-full mt-6">
							Set Password
						</Button>
						<div onClick={() => NAVIGATE("/")} className="mt-4 text-sm text-center cursor-pointer hover:underline">
							Go to Sign in page
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default Signup;
