import React, { useState } from "react";
import { Button, Toast } from "../../../../../../../../Components";
import { useNavigate } from "react-router-dom";
import FlightItinerary from "./itinerary";
import { useUserPolicy } from "../../../../../../../../Hooks/useUserPolicy";
import { useUserConfig } from "../../../../../../../../Hooks/useUserConfig";
import AskApproval from "./askApproval";
import SendToAdmin from "./sendToAdmin";
import { apis, post } from "../../../../../../../../Utils/axios";
import FareRules from "./fareRules";
import FlightDetails from "./details";

function Summary({ selectedFlight, passengers, approvalId, approval, requestId, fromCode, toCode, date, adult, child, infant, cabinClass }) {
	const { checkUserPermission } = useUserConfig();
	const isTA = checkUserPermission("TA");
	const { travelPolicy } = useUserPolicy();
	const approvedAmount = approval?.amount;
	const adminBooking = travelPolicy?.domesticFlight?.adminBooking && !isTA;
	const askApproval = !selectedFlight?.selectedFare?.inPolicy && !isTA && (approvedAmount ? selectedFlight?.selectedFare?.price > Number(approvedAmount) : true);
	const violations = [...selectedFlight?.selectedFare?.violations, ...selectedFlight?.violations];
	const { segments } = selectedFlight;
	const departure = segments[0];
	const NAVIGATE = useNavigate();
	const [Loading, setLoading] = useState();

	const confirmFare = () => {
		setLoading(true);
		post(
			apis.confirmFlightFare,
			{
				from: fromCode,
				to: toCode,
				date: String(date),
				adult: String(adult),
				child: String(child),
				infant: String(infant),
				cabinClass,
				requestId,
				approvalId,
				flightOptionKey: selectedFlight?.selectedFare?.fareKey,
				flightSearchKey: selectedFlight?.sid,
			},
			(r, e) => {
				if (r?.statusMessage === "success") {
					NAVIGATE(`/app/travel/flights/book`, {
						state: {
							fareConfirmDetails: r,
							flight: selectedFlight,
							flightOptionKey: r.selectedFlightOptionKey,
							flightSearchKey: selectedFlight?.sid,
							passengers,
							approvalId,
							requestId,
						},
					});
				} else if (e) {
					Toast.handleError(e);
					setLoading(false);
				}
			}
		);
	};

	return (
		<div className="flex flex-col justify-between w-full h-full fade-in-right">
			<div className="flex flex-col gap-6 p-4 overflow-y-scroll">
				<div className="align-center">
					<img src={departure.logo} alt="airline-logo" className="w-10 h-10 mr-4 rounded-full" />
					<h3>{departure.airlineName}</h3>
				</div>
				<div>
					<FlightItinerary selectedFlight={selectedFlight} />
				</div>
				<div>
					<FlightDetails baggage={{ checkin: selectedFlight?.segments[0].checkinBaggage, cabin: selectedFlight?.segments[0].cabinBaggage }} />
				</div>
				<div>
					<FareRules {...{ refundable: selectedFlight?.selectedFare?.refundable, fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey: selectedFlight?.selectedFare?.fareKey, flightSearchKey: selectedFlight?.sid }} />
				</div>
			</div>
			<div className="p-4">
				<div>
					<div className="gap-1 mb-2 text-sm align-center">
						For {passengers.adult} Adult
						{passengers.child > 0 && <>, {passengers.child} Child</>}
						{passengers.infant > 0 && <>, {passengers.infant} Infant</>}
					</div>
					<h1>
						Total {selectedFlight?.selectedFare?.currency} {selectedFlight?.selectedFare?.price}
					</h1>
				</div>
				<div className="mt-4">
					{askApproval ? (
						<AskApproval {...{ flight: selectedFlight, fare: selectedFlight?.selectedFare, type: "oneway", violations }} />
					) : adminBooking ? (
						<SendToAdmin {...{ flight: selectedFlight, fare: selectedFlight?.selectedFare, type: "oneway", approvalId }} />
					) : (
						<Button loading={Loading} variant="primary" onClick={confirmFare} className="w-full">
							Continue
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}

export default Summary;
