import React from "react";
import { Avatar } from "../..";
import { FiArrowUpRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function InlineEmployee({ employee = {}, className = "" }) {
	const NAVIGATE = useNavigate();
	const { photo, id } = employee;

	return (
		<div onClick={() => NAVIGATE(`/app/users/${id}`)} className="inline-block px-2 py-1 align-middle rounded-md cursor-pointer bg-light hover:bg-primary hover:text-white">
			<div className={`align-center gap-x-1 ${className}`}>
				<Avatar photo={photo} className={"w-4 h-4"} />
				<div className="text-xs">
					{employee.fName} {employee.lName}
				</div>
				<FiArrowUpRight />
			</div>
		</div>
	);
}

export default InlineEmployee;
