import React from "react";
import { Badge } from "../../../../../../../../Components";
import { useClientSettings } from "Hooks/useClientSettings";

function Cancellation() {
	const { currency } = useClientSettings();
	return (
		<>
			<h6>Fare Rules</h6>
			<Badge className="mt-2" variant="success">
				Full Refundable
			</Badge>

			<div className="mt-4 text-xs">Cancellation Charges</div>

			<div className="mt-2 text-xs flex-center-between text-secondary">
				<div>TPP Fee</div>
				<div className="text-end">{currency} 100</div>
			</div>

			<div className="mt-3 text-xs">Airline Charges</div>
			<div className="mt-2 text-xs flex-center-between text-secondary">
				<div>0 hrs to 4 hrs</div>
				<div className="text-end">No-Refund</div>
			</div>
			<div className="mt-2 text-xs flex-center-between text-secondary">
				<div>Before 4 hrs</div>
				<div className="text-end">100%</div>
			</div>
		</>
	);
}

export default Cancellation;
