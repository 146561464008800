import React, { useState } from "react";
import { Button, Toast } from "../../../../../../../../Components";
import { useNavigate } from "react-router-dom";
import FlightItinerary from "./itinerary";
import { useUserPolicy } from "../../../../../../../../Hooks/useUserPolicy";
import { useUserConfig } from "../../../../../../../../Hooks/useUserConfig";
import AskApproval from "./askApproval";
import SendToAdmin from "./sendToAdmin";
import { apis, post } from "../../../../../../../../Utils/axios";
import FlightDetails from "./details";
import FareRules from "./fareRules";
import { useClientSettings } from "Hooks/useClientSettings";
import SelectedFlight from "Components/Travel/Request/ViewRequest/Types/flight/selectedFlight";

function Summary({ approval, selectedFlight = {}, journeyKey, passengers = {}, approvalId, requestId, fromCode, toCode, date, returnDate, adult, child, infant, cabinClass }) {
	console.log(selectedFlight);

	const { checkUserPermission } = useUserConfig();
	const { travelPolicy } = useUserPolicy();
	const { currency } = useClientSettings();
	const adminBooking = travelPolicy?.domesticFlight?.adminBooking && !checkUserPermission("TA");
	const askApproval = !selectedFlight?.selectedFare?.inPolicy && !checkUserPermission("TA");
	const onwardViolations = selectedFlight?.selectedFare ? [...selectedFlight?.selectedFare?.violations, ...selectedFlight?.violations] : [];
	const returnViolations = selectedFlight?.selectedFare ? [...selectedFlight?.selectedFare?.violations, ...selectedFlight?.violations] : [];

	const approvedAmount = approval?.amount;
	const segments = selectedFlight?.onwardSegments || selectedFlight?.returnSegments;
	const flightDetails = segments[0];
	const NAVIGATE = useNavigate();
	const [Loading, setLoading] = useState();

	const confirmFare = () => {
		setLoading(true);
		post(
			apis.confirmRoundFlightFare,
			{
				from: fromCode,
				to: toCode,
				date: String(date),
				returnDate: String(returnDate),
				adult: String(adult),
				child: String(child),
				infant: String(infant),
				cabinClass,
				requestId,
				approvalId,
				flightOptionKey: selectedFlight?.selectedFare?.fareKey,
				flightSearchKey: selectedFlight?.sid,
			},
			(r, e) => {
				if (r?.statusMessage === "success") {
					NAVIGATE(`/app/travel/flights/book`, {
						state: {
							fareConfirmDetails: r,
							flight: {
								...selectedFlight,
								segments: selectedFlight?.onwardSegments,
								returnSegments: selectedFlight?.returnSegments,
								duration: selectedFlight?.onwardDuration,
								returnDuration: selectedFlight?.returnDuration,
							},
							flightOptionKey: r.selectedFlightOptionKey,
							flightSearchKey: selectedFlight?.sid,
							passengers,
							approvalId,
							requestId,
						},
					});
				} else if (e) {
					Toast.handleError(e);
					setLoading(false);
				}
			}
		);
	};
	return (
		<div className="flex flex-col justify-between w-full h-full fade-in-right">
			<div className="flex flex-col gap-6 p-4 overflow-y-scroll">
				<div className="align-center">
					<img src={flightDetails.logo} alt="airline-logo" className="w-10 h-10 mr-4 rounded-full" />
					<h3>{flightDetails.airlineName}</h3>
				</div>
				<div>
					<FlightItinerary onwardSegments={selectedFlight?.onwardSegments} returnSegments={selectedFlight?.returnSegments} />
				</div>
				{selectedFlight?.onwardSegments && (
					<>
						<div>
							<FlightDetails baggage={{ checkin: selectedFlight?.onwardSegments[0].checkinBaggage, cabin: selectedFlight?.onwardSegments[0].cabinBaggage }} />
						</div>
						<div>
							<FareRules {...{ refundable: selectedFlight?.onwardFare?.refundable, fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey: selectedFlight?.onwardFare?.fareKey, flightSearchKey: selectedFlight?.onwardSid }} />
						</div>
					</>
				)}
			</div>
			<div className="p-4">
				<div>
					<div className="gap-1 mb-2 text-sm align-center">
						For {passengers.adult} Adult
						{passengers.child > 0 && <>, {passengers.child} Child</>}
						{passengers.infant > 0 && <>, {passengers.infant} Infant</>}
					</div>
					<h1>
						Total {currency} {selectedFlight?.selectedFare?.price + selectedFlight?.selectedFare?.price}
					</h1>
				</div>
				<div className="mt-4">
					{askApproval ? (
						<AskApproval
							{...{
								flight: selectedFlight,
								fare: selectedFlight?.selectedFare,
								violations: selectedFlight.violations,
								type: "roundtrip",
							}}
						/>
					) : adminBooking ? (
						<SendToAdmin
							{...{
								selectedFlight,
								type: "round",
								approvalId,
							}}
						/>
					) : (
						<Button loading={Loading} variant="primary" onClick={confirmFare} className="w-full">
							Continue
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}

export default Summary;
