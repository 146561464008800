import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { apis, get } from "../../Utils/axios";
import moment from "moment";
import { TbReload } from "react-icons/tb";
import { Spinner } from "..";

function ReceiptStoreCode({ size = 100, className = "" }) {
	const [Loading, setLoading] = useState(false);
	const [Code, setCode] = useState(JSON.parse(localStorage.getItem("receiptStore")) || {});
	useEffect(() => {
		if (!Code.code || Code.expiry <= moment().valueOf()) {
			getCode();
		}
	}, [Code]);

	const getCode = () => {
		if (!Loading) {
			setLoading(true);
			// get(apis.getStoreCode, {}, (r, e) => {
			// 	if (r) {
			// 		setCode(r);
			// 		localStorage.setItem("receiptStore", JSON.stringify(r));
			// 		setLoading(false);
			// 	} else if (e) {
			// 		setLoading(false);
			// 	}
			// });
		}
	};

	return (
		<div style={{ width: size, height: size }} className={`relative group ${className}`}>
			<QRCode size={size} value={`${origin}/store?code=${Code.code}`} />
			<div onClick={getCode} className="absolute left-0 top-0 right-0 bottom-0 bg-[rgba(0,0,0,0.6)] text-white flex-center text-3xl cursor-pointer opacity-0 group-hover:opacity-100 transition-all">
				{Loading ? <Spinner /> : <TbReload />}
			</div>
		</div>
	);
}

export default ReceiptStoreCode;
