import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Button, Img, OutOfPolicyBadge, Popup } from "../..";
import { FiMap } from "react-icons/fi";
import { useUserConfig } from "../../../Hooks/useUserConfig";
import { useClientSettings } from "Hooks/useClientSettings";

function StayCard({ id, thumbnail, inPolicy, violations, amenities = [], rating = 0, starRating = 0, rooms = 0, name = "", locality = "", distance, className = "", onClick = () => {} }) {
	const { currency } = useClientSettings();
	const { checkUserPermission } = useUserConfig();
	const askApproval = !inPolicy && !checkUserPermission("TA");
	const nights = rooms[0]?.rateBreakdown.length;
	const launchMaps = () => {
		window.open(`https://www.google.com.sa/maps/search/${locality.latitude},${locality.longitude}`, "_blank");
	};

	return (
		<>
			<div className={`relative flex flex-col justify-between max-w-[20rem] min-w-[15rem] border hover:shadow-md cursor-pointer rounded-lg p-2 shadow-sm ${askApproval ? "bg-whitish" : "bg-canvas"} ${className}`}>
				<Swiper slidesPerView={"auto"} onSlideChange={(i) => {}} modules={[Navigation]} navigation={true} className="w-full rounded-lg swiper-carousel">
					<SwiperSlide>
						<Img src={thumbnail} className="w-full aspect-[5/3]" />
					</SwiperSlide>
				</Swiper>

				<div onClick={() => onClick(id)} className="p-2 mt-2">
					<div className="flex gap-x-2">
						{amenities.length > 0 && (
							<Popup button="Amenities" btnClass="btn btn-outlined btn-xs">
								<div className="p-4">
									{amenities.map((amenity, i) => {
										const { category, amenities } = amenity;
										return (
											<div key={i} className="mb-6">
												<h4 className="mb-2">{category}</h4>
												<div className="flex flex-wrap gap-2">
													{amenities.map((amenity, i) => (
														<div key={i} className="chip">
															{amenity}
														</div>
													))}
												</div>
											</div>
										);
									})}
								</div>
							</Popup>
						)}
						<Button onClick={launchMaps} variant="outlined" className="btn-xs">
							<FiMap className="mr-1" />
							Map
						</Button>
					</div>
					<div className="mt-2 font-semibold text-ellipsis">{name}</div>
					<div className="text-sm text-secondary text-ellipsis">{locality.name}</div>
					<div className="mt-2 flex-center-between">
						<div className="text-sm">
							<span className="font-semibold">
								{currency} {Math.ceil(rooms[0]?.amount / nights)}
							</span>{" "}
							<span className="text-secondary">/ night</span>
						</div>
						<div className="align-center">
							{askApproval ? (
								<OutOfPolicyBadge violations={violations} />
							) : (
								<>
									<div className="mr-2 text-sm">
										{[...Array(parseInt(starRating))].map((e, i) => {
											return <span key={i}>⭑</span>;
										})}
									</div>
									{distance && <div className="p-1 text-xs font-semibold border rounded-md bg-light">{distance} Km</div>}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default StayCard;
