import React from "react";
import { Button, Input, Modal, FileInput, Toast, Form } from "../..";
import { useState } from "react";
import { validateEmail, validatePhoneNumber } from "../../../Utils/utils";
import { apis, post } from "../../../Utils/axios";
import { useAdminPermission } from "../../../Hooks/useAdminPermission";

function AddOffice({ onReload = () => {}, btnLabel = "+ Add Office", btnClass = "", btnVariant = "primary" }) {
	const { checkAdminPermission } = useAdminPermission();
	const [NewOffice, setNewOffice] = useState();
	const initial = {
		name: "",
		description: "",
		address: "",
		email: "",
		phone: "",
		logo: "",
	};
	const [Values, setValues] = useState(initial);
	const [Loader, setLoader] = useState();

	const onSubmit = () => {
		if (!validateEmail(Values.email)) {
			return Toast.error("Please enter valid email");
		}
		if (!validatePhoneNumber(Values.phone)) {
			return Toast.error("Please enter valid phone number");
		}
		if (!Values.name) {
			return Toast.error("Please enter name");
		}
		if (!Values.address) {
			return Toast.error("Please enter address");
		}

		setLoader(true);
		post(apis.addOffice, Values, (r, e) => {
			if (r) {
				setLoader();
				onReload();
				setNewOffice();
				setValues(initial);
				Toast.success("Office added successfully");
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
				setLoader();
			}
		});
	};

	if (!checkAdminPermission("EDIT_ORGANIZATION")) {
		return null;
	}

	return (
		<>
			<Button onClick={() => setNewOffice(true)} variant={btnVariant} className={btnClass}>
				{btnLabel}
			</Button>
			<Modal open={NewOffice} onClose={() => setNewOffice()}>
				<div className="p-6">
					<h1>New Office</h1>
					<div className="mt-2 text-label">Offices are the key hierarchy level which admins manage. You can add departments to offices and apply policies to offices.</div>
					<Form className="flex flex-col mt-4 gap-y-4">
						<Input required value={Values.name} onChange={(name) => setValues((prev) => ({ ...prev, name }))} label="Office Name" placeholder="Name" />
						<Input value={Values.address} onChange={(address) => setValues((prev) => ({ ...prev, address }))} label="Office Address" placeholder="Address" />
						<div className="grid grid-cols-2 gap-4">
							<Input required value={Values.email} onChange={(email) => setValues((prev) => ({ ...prev, email }))} label="Email" placeholder="Email Address" />
							<Input type="number" required value={Values.phone} onChange={(phone) => setValues((prev) => ({ ...prev, phone }))} label="Phone" placeholder="Phone" />
						</div>
						{/* <FileInput onDelete={() => setValues((prev) => ({ ...prev, logo: "" }))} file={Values.logo} label="Office Logo" onUpload={(obj) => setValues((prev) => ({ ...prev, logo: obj.file.url }))} /> */}
						<Button loading={Loader} onClick={onSubmit} className="w-full mt-4">
							Create Office
						</Button>
					</Form>
				</div>
			</Modal>
		</>
	);
}

export default AddOffice;
