import React from "react";
import { Avatar } from "../../../../../Components";

function Chat({ receiver, active, onSelect = () => {} }) {
	//Remove self from chat participants
	const { email, photo, unreadMessages } = receiver;

	const hasUnreadMessages = unreadMessages > 0;

	return (
		<>
			<div onClick={onSelect} className={`p-2 hover:bg-light gap-x-2 rounded-md align-center cursor-pointer ${active ? "bg-light" : ""}`}>
				<Avatar photo={photo} className="w-10 h-10" />
				<div className="flex-grow">
					<div className="flex-center-between">
						<div className={`text-ellipsis text-sm`}>
							{receiver.fName} {receiver.lName}
						</div>
						<div className={`text-ellipsis ${hasUnreadMessages ? "text-primary text-xs" : "text-label"}`}>{""}</div>
					</div>
					<div className={`text-ellipsis text-label`}>{email}</div>
					<div className="flex items-start justify-between">
						<div className={`text-ellipsis text-label`}>{""}</div>
						{hasUnreadMessages && <div className={`text-xxs bg-primary text-white px-1 min-w-[14px] flex-center rounded-full `}>{unreadMessages}</div>}
					</div>
				</div>
			</div>
		</>
	);
}

export default Chat;
