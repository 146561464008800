import React, { useEffect, useState } from "react";
import { Badge, Button, Modal, Avatar } from "../..";
import { FiArrowUpRight, FiMessageSquare } from "react-icons/fi";
import { MdOutlineArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function EmployeeCard({ className = "", employee, id, size = "md" }) {
	const SIZES = {
		xs: {
			avatar: "w-6 h-6",
			name: "text-xs",
			email: "text-xxs",
			padding: "p-1 gap-x-1",
		},
		sm: {
			avatar: "w-8 h-8",
			name: "text-xs",
			email: "text-xs",
			padding: "p-2 gap-x-2",
		},
		md: {
			avatar: "w-10 h-10",
			name: "text-base",
			email: "text-xs",
			padding: "p-2 gap-x-2",
		},
		lg: {
			avatar: "w-12 h-12",
			name: "text-lg",
			email: "text-sm",
			padding: "p-3  gap-x-3",
		},
	};

	const NAVIGATE = useNavigate();
	const [ShowDetails, setShowDetails] = useState();

	useEffect(() => {
		if (!employee) {
			fetchDetails(id);
		}
	}, [employee, id]);

	const fetchDetails = (id) => {};

	return (
		<>
			<div className={`${SIZES[size].padding} rounded-md cursor-pointer align-center ${className}`}>
				<Avatar photo={employee?.photo} className={SIZES[size]?.avatar} />
				<div>
					<div className="align-center gap-x-1">
						<div className={`${SIZES[size].name} font-semibold text-ellipsis`}>
							{employee?.fName} {employee?.lName}
						</div>
						<div
							onClick={(e) => {
								e.stopPropagation();
								setShowDetails(true);
							}}
							className="rounded-sm hover:bg-dark hover:text-white"
						>
							<FiArrowUpRight />
						</div>
					</div>
					<div className={`${SIZES[size].email} text-secondary text-ellipsis`}>{employee?.email}</div>
				</div>
			</div>
			<Modal open={ShowDetails} onClose={() => setShowDetails()}>
				<div className="flex flex-col items-center p-8 text-center gap-y-4">
					<Avatar photo={employee?.photo} className="w-24 h-24" />
					<div>
						<div className="text-2xl font-semibold">{`${employee?.fName} ${employee?.lName}`}</div>
						<div className="text-sm text-secondary">{employee?.email}</div>
					</div>
					{employee?.team && (
						<div className="mt-2 align-center">
							<Badge>{employee?.team?.name} Team</Badge>
							<MdOutlineArrowRight />
							<Badge>{employee?.office?.name} Office</Badge>
						</div>
					)}
					{/* <Button onClick={() => NAVIGATE(`/app/chat?userId=${employee?.id}`)} variant="outlined" className="mt-4 btn-sm">
						<FiMessageSquare className="mr-2" />
						Open in Chat
					</Button> */}
				</div>
			</Modal>
		</>
	);
}

export default EmployeeCard;
