import React from "react";
import { StampToDate } from "../../../../Utils/utils";
import EmployeeInfo from "../../../Cards/EmployeeInfo";
import { Disclosure, LabelValue } from "../../..";
import StatusIndicator from "../Components/status";
import { useClientSettings } from "Hooks/useClientSettings";

function RequestDetails({ id, date, requestedBy, amount, bookingDate, status, type, notes }) {
	const { currency } = useClientSettings();
	return (
		<>
			<div className="p-4 rounded-lg shadow-sm bg-canvas">
				<h4>Details</h4>
				<div className="grid grid-cols-2 gap-4 mt-4">
					<div className="text-sm font-semibold">
						<div className="text-label">Request ID</div>
						<div>REQ-{id}</div>
					</div>
					<LabelValue label="Requested on">
						<div>{StampToDate(date)}</div>
					</LabelValue>
					<LabelValue label="Booking Date">
						<div>{StampToDate(bookingDate)}</div>
					</LabelValue>
					<LabelValue label="Request Amount">
						<div>
							{currency} {amount}
						</div>
					</LabelValue>
					<LabelValue label="Request Status">
						<StatusIndicator status={status} />
					</LabelValue>
				</div>
			</div>
			<div className="p-4 rounded-lg shadow-sm bg-canvas">
				<Disclosure defaultOpen={true} heading={<h4>Requested By</h4>}>
					<EmployeeInfo employee={requestedBy} className="mt-2" />
				</Disclosure>
			</div>
		</>
	);
}

export default RequestDetails;
