import React, { useEffect, useState } from "react";
import { FiFilter, FiMap } from "react-icons/fi";
import { Searchbox, ViewYourPolicy, Button, RangeInput, Checkbox } from "../../..";
import { useClientSettings } from "Hooks/useClientSettings";

function StayFilters({ map, setMap = () => {}, stays = [], filters = {}, updateFilters = () => {} }) {
	const { currency } = useClientSettings();
	const [Chains, setChains] = useState([]);
	const [MaxRange, setMaxRange] = useState(0);

	useEffect(() => {
		const chains = [];
		let maxPrice = 0;
		stays.forEach((stay) => {
			stay.rooms.forEach((room) => {
				if (room?.amount > maxPrice) {
					maxPrice = room?.amount;
				}
			});
			if (stay.chain && !chains.includes(stay.chain)) {
				chains.push(stay.chain);
			}
		});
		setChains(chains);
		setMaxRange(maxPrice + 500);
		updateFilters({
			...filters,
			maxPrice,
		});
	}, [stays]);

	const applyFilter = (type, checked, value) => {
		if (checked) {
			updateFilters({
				...filters,
				[type]: [...filters[type], value],
			});
		} else {
			updateFilters({
				...filters,
				[type]: filters[type].filter((a) => a !== value),
			});
		}
	};

	return (
		<div className="flex flex-col justify-between h-full">
			<div>
				<ViewYourPolicy />
				<div className="mt-6 align-center">
					<FiFilter className="mr-2" />
					<h5>Stay Filters</h5>
				</div>

				<div className="mt-6">
					<Searchbox defaultValue={filters.query} onChange={(query) => updateFilters({ ...filters, query })} placeholder="Search by hotel name" cmd="n" />
				</div>

				{/* <div className="mt-6">
					<Searchbox placeholder="Distance from" cmd="d" label="Distance From" />
				</div> */}

				<div className="mt-6">
					<div className="mb-2 text-sm font-semibold">Quick filters</div>
					<div>
						<Checkbox checked={filters.inPolicy} onChange={(checked) => updateFilters((prev) => ({ ...prev, inPolicy: checked }))} label="Stays that are in my policy" className="mt-2" />
						<Checkbox checked={filters.fullyRefundable} onChange={(checked) => updateFilters((prev) => ({ ...prev, fullyRefundable: checked }))} label="Refundable stays" className="mt-2" />
					</div>
				</div>

				<div className="mt-6">
					<div className="text-sm font-semibold flex-center-between">
						<div className="mb-2">Price</div>
						<div className="font-bold text-dark">
							{currency} {filters.maxPrice}
						</div>
					</div>
					<RangeInput max={MaxRange} value={filters.maxPrice} onChange={(value) => updateFilters({ ...filters, maxPrice: value })} unit="{currency}" />
				</div>

				<div className="mt-6">
					<div className="mb-2 text-sm font-semibold">Star Rating</div>
					<div>
						<Checkbox checked={filters.stars.includes(3)} onChange={(checked) => applyFilter("stars", checked, 3)} label="(3 Stars) ⭑⭑⭑" className="mt-2 !text-xs" />
						<Checkbox checked={filters.stars.includes(4)} onChange={(checked) => applyFilter("stars", checked, 4)} label="(4 Stars) ⭑⭑⭑⭑" className="mt-2 !text-xs" />
						<Checkbox checked={filters.stars.includes(5)} onChange={(checked) => applyFilter("stars", checked, 5)} label="(5 Stars) ⭑⭑⭑⭑⭑" className="mt-2 !text-xs" />
					</div>
				</div>

				<div className="mt-6">
					<div className="mb-2 text-sm font-semibold">Hotel Chains</div>
					<div>
						{Chains.map((chain, i) => (
							<Checkbox checked={filters.chains.includes(chain)} onChange={(checked) => applyFilter("chains", checked, chain)} key={i} label={chain} className="mt-2" />
						))}
					</div>
				</div>
			</div>
			<div className="pt-4">
				<Button onClick={() => (map ? setMap() : setMap(true))} className="w-full">
					<FiMap className="mr-2" />
					{map ? "Hide Map" : "Show Map"}
				</Button>
			</div>
		</div>
	);
}

export default StayFilters;
