import React, { useEffect, useState } from "react";
import { Button, CopyValue, Loader, Toast, Modal } from "../../..";
import { FiDownload } from "react-icons/fi";
import "swiper/css";
import "swiper/css/navigation";
import { apis, get } from "../../../../Utils/axios";
import BookedFor from "./bookedFor";
import { useClientSettings } from "Hooks/useClientSettings";

function TrainBooking({ bookingId, onClose = () => {} }) {
	const { currency } = useClientSettings();
	const [Loading, setLoading] = useState(true);
	const [Booking, setBooking] = useState({});
	const { id, amount, board, drop, coach, type, booking, status, passengers, bookingId: pnr } = Booking;

	useEffect(() => {
		if (bookingId) {
			fetchBooking(bookingId);
		}
	}, [bookingId]);

	const fetchBooking = (bookingId) => {
		setLoading(true);
		get(apis.getTrainBookingDetails, { bookingId }, (r, e) => {
			if (r) {
				setBooking(r);
				setLoading(false);
			} else if (e) {
				Toast.handleError(e);
				setLoading(false);
			}
		});
	};

	return (
		<Modal open={bookingId} onClose={onClose} contentClass="!max-w-[40%]">
			<Loader loading={Loading} />
			<div className="p-4 rounded-lg bg-light">
				<div className="rounded-lg shadow-md bg-canvas">
					<div className="grid grid-cols-2 gap-4 p-6">
						<div className="text-xs font-semibold">
							<div className="text-label">Board Station</div>
							<div>{board}</div>
						</div>
						<div className="text-xs font-semibold">
							<div className="text-label">Drop Station</div>
							<div>{drop}</div>
						</div>
						<div className="text-xs font-semibold">
							<div className="text-label">Booking ID</div>
							<CopyValue>TRN-{bookingId}</CopyValue>
						</div>
						<div className="text-xs font-semibold">
							<div className="text-label">Coach Type</div>
							<div>{coach}</div>
						</div>
						<div className="text-xs font-semibold">
							<div className="text-label">PNR</div>
							<CopyValue>{pnr}</CopyValue>
						</div>
						<div className="text-xs font-semibold">
							<div className="text-label">Amount</div>
							<div>
								{currency} {amount}
							</div>
						</div>
					</div>
				</div>
				<BookedFor type="stay" bookingId={bookingId} travellers={passengers} onReload={() => fetchBooking(bookingId)} />
				<div className="flex mt-4 gap-x-2">
					<Button variant="outlined" className="flex-grow">
						<FiDownload className="mr-2" />
						Download Booking
					</Button>
					<Button variant="danger" className="flex-grow">
						Cancel Booking
					</Button>
				</div>
			</div>
		</Modal>
	);
}

export default TrainBooking;
