import React from "react";
import { FaBus } from "react-icons/fa";
import { StampTo24hr, StampToDate } from "../../../../../../../../Utils/utils";
import moment from "moment";
import { Button } from "../../../../../../../../Components";
import { useNavigate } from "react-router-dom";
import { useClientSettings } from "Hooks/useClientSettings";

function BusCard({ operator, rating, bus, from, to, departure, arrival, amount, date, mode, type, onSelect = () => {}, className = "" }) {
	const { currency } = useClientSettings();
	const NAVIGATE = useNavigate();

	return (
		<div className="p-4 mt-4 border rounded-md shadow-sm ">
			<div className="flex-center-between">
				<h2>{operator.name}</h2>
				<div className="align-center gap-x-2">
					<div className="text-sm text-secondary">{bus}</div>
					<div className="p-1 text-xs font-bold border rounded-md border-muted bg-light">{rating}</div>
				</div>
			</div>
			<div className="grid grid-cols-2 mt-4 gap-x-6">
				<div className={`align-center gap-x-4 ${className}`}>
					<div className="max-w-[8rem]">
						<div className="text-xl font-bold whitespace-nowrap">{StampTo24hr(departure.time)}</div>
						<div className="text-sm text-secondary">{StampToDate(departure.time)}</div>
						<div className="text-sm text-secondary">{departure.point}</div>
						<div className="text-sm text-secondary">{from.name}</div>
					</div>
					<div className="flex-grow min-w-[5rem]">
						<div className="align-center">
							<div className="w-2 h-2 border rounded-full border-dark" />
							<div className="flex-grow border-t border-dashed border-muted" />
							<FaBus />
							<div className="flex-grow border-t border-dashed border-muted" />
							<div className="w-2 h-2 border rounded-full border-dark" />
						</div>
						<div className="mt-2 text-xs text-center text-secondary whitespace-nowrap">2hr 30min</div>
					</div>
					<div className="max-w-[8rem]">
						<div className="text-xl font-bold whitespace-nowrap">{StampTo24hr(arrival.time)}</div>
						<div className="text-sm text-secondary">{StampToDate(arrival.time)}</div>
						<div className="text-sm text-secondary">{arrival.point}</div>
						<div className="text-sm text-secondary">{to.name}</div>
					</div>
				</div>
				<div className="flex flex-col items-end justify-between">
					<div className="flex gap-x-2">
						<Button variant="outlined" className="btn-xs">
							Amenities
						</Button>
						<Button variant="outlined" className="btn-xs">
							Pick & Drops
						</Button>
						<Button variant="outlined" className="btn-xs">
							Cancellation
						</Button>
					</div>
					<div className="mt-4 align-center gap-x-4">
						<h2>{currency} 600</h2>
						<Button onClick={() => NAVIGATE("/app/travel/buses/book")} className="btn-sm">
							Select Seats
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BusCard;
