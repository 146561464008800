import { useClientSettings } from "Hooks/useClientSettings";
import React from "react";
import { FaShieldAlt } from "react-icons/fa";

function HasApproval({ approval }) {
	const { currency } = useClientSettings();
	if (approval) {
		const { amount } = approval;
		return (
			<div className="p-2 mt-4 rounded-md bg-compliment fade-in-down">
				<div className="px-2 flex-center-between text-accent">
					<h5 className="gap-2 align-center">
						<FaShieldAlt /> You have an approval
					</h5>
					<div className="text-xs">
						You can book any stay upto {currency} {amount}
					</div>
				</div>
			</div>
		);
	}

	return null;
}

export default HasApproval;
