import React from "react";
import FlightCard from "../../../../Pages/App/Employee/Travel/Flights/Oneway/Result/Components/flightCard";
import { StampToDate } from "../../../../Utils/utils";
import { useClientSettings } from "Hooks/useClientSettings";

function FlightApproval({ fare, flight, type }) {
	const { currency } = useClientSettings();
	const renderFlight = (flight) => {
		return (
			<div className="border rounded-md">
				<FlightCard {...flight} />
			</div>
		);
	};

	return (
		<>
			<div className="p-4 rounded-lg shadow-sm bg-canvas">
				<h4>User's Choice</h4>
				<div className="flex flex-col mt-4 gap-y-2">
					{type === "oneway" ? (
						<>
							<div className="flex-center-between">
								<label>Departure on {StampToDate(flight.segments[0].departureTime)}</label>
								<label>
									{currency} {fare.price}
								</label>
							</div>
							{renderFlight(flight)}
						</>
					) : type === "roundtrip" ? (
						<>
							<div className="flex-center-between">
								<label>Departure on {StampToDate(flight.onwardSegments[0].departureTime)}</label>
								<label>
									{currency} {flight.selectedFare.price}
								</label>
							</div>
							{renderFlight(flight)}
							<div className="flex-center-between">
								<label>Return on {StampToDate(flight.returnSegments[0].departureTime)}</label>
								<label>
									{currency} {flight.selectedFare.price}
								</label>
							</div>
							{renderFlight(flight)}
						</>
					) : null}
				</div>
			</div>
		</>
	);
}

export default FlightApproval;
