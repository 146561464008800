import React, { useEffect, useState } from "react";
import { BackButton, Tabs, Toast, Avatar } from "../../../../../../Components";
import { useParams } from "react-router-dom";
import { apis, get } from "../../../../../../Utils/axios";
import { removeNullKeys } from "../../../../../../Utils/utils";
import { useClientSettings } from "../../../../../../Hooks/useClientSettings";
import { FaBed, FaCar, FaPlane, FaTrain, FaUser } from "react-icons/fa";
import { Tab } from "@headlessui/react";
import FlightBookings from "./Bookings/Flights";
import StayBookings from "./Bookings/Stays";
import CabBookings from "./Bookings/Cab";
import TrainBookings from "./Bookings/Train";
import TravellerProfile from "./Profile";

function ViewTraveller() {
	const { userId } = useParams();
	const { isEnabled } = useClientSettings();
	const [UserDetails, setUserDetails] = useState({
		fName: `Employee`,
		lName: userId,
	});

	useEffect(() => {
		fetchDetails(userId);
	}, [userId]);

	const fetchDetails = (userId) => {
		get(`/travellers/${userId}`, {}, (r, e) => {
			if (r) {
				setUserDetails(removeNullKeys(r));
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
			}
		});
	};

	return (
		<div className="p-4">
			<div className="mb-6 align-center gap-x-2">
				<BackButton size="lg" className="border" />
				<Avatar photo={UserDetails.photo} />
				<h2>
					{UserDetails.fName} {UserDetails.lName}
				</h2>
			</div>
			<div className="pb-24">
				<Tabs
					options={[
						<div className="align-center">
							<FaUser className="mr-1" />
							Profile
						</div>,
						<div className="align-center">
							<FaPlane className="mr-1" />
							Flights
						</div>,
						// <div className="align-center">
						// 	<FaBed className="mr-1" />
						// 	Stays
						// </div>,
						// ...(isEnabled("ORGANIZATION")
						// 	? [
						// 			<div className="align-center">
						// 				<FaCar className="mr-1" />
						// 				Cabs
						// 			</div>,
						// 			<div className="align-center">
						// 				<FaTrain className="mr-1" />
						// 				Trains
						// 			</div>,
						// 		]
						// 	: []),
						// <div className='align-center'><FaBus className='mr-1'/>Buses</div>,
					]}
				>
					<Tab.Panel>
						<TravellerProfile profile={UserDetails} />
					</Tab.Panel>
					<Tab.Panel>
						<FlightBookings />
					</Tab.Panel>
					{/* <Tab.Panel>
						<StayBookings />
					</Tab.Panel>
					<Tab.Panel>
						<CabBookings />
					</Tab.Panel>
					<Tab.Panel>
						<TrainBookings />
					</Tab.Panel> */}
					{/* <Tab.Panel>
                    <BusBookings/>
                </Tab.Panel> */}
				</Tabs>
			</div>
		</div>
	);
}

export default ViewTraveller;
