import React, { useState } from "react";
import FlightCard from "./Components/flightCard";
import FareCard from "./Components/fareCard";
import Sort from "./Components/sort";
import { classifyTime } from "../../../../../../../Utils/utils";
import { useUserPolicy } from "../../../../../../../Hooks/useUserPolicy";
import UserChoice from "./Components/userChoice";
import HasApproval from "./Components/hasApproval";

function FlightResult({ approval, filters = {}, onSelect = () => {}, SelectedFlight, flights = [], journeyKey }) {
	const { travelPolicy } = useUserPolicy();
	const { showOutOfPolicyOptions = true } = travelPolicy?.domesticFlight || {};

	const [SortBy, setSortBy] = useState({
		type: "price",
		order: "asc",
	});

	const handleSelect = (selectedFare, flight) => {
		if (selectedFare) {
			onSelect({ ...flight, selectedFare });
		} else {
			onSelect();
		}
	};

	const sortFlights = (prev, next) => {
		if (SortBy.type === "price") {
			const prevPrice = prev.fares[0].price;
			const nextPrice = next.fares[0].price;
			return SortBy.order === "des" ? nextPrice - prevPrice : prevPrice - nextPrice;
		} else if (SortBy.type === "departure") {
			const prevDeparture = prev.segments[0]?.departureTime;
			const nextDeparture = next.segments[0]?.departureTime;
			return SortBy.order === "des" ? nextDeparture - prevDeparture : prevDeparture - nextDeparture;
		} else if (SortBy.type === "arrival") {
			const prevArrival = prev.segments[prev.segments.length - 1]?.arrivalTime;
			const nextArrival = next.segments[prev.segments.length - 1]?.arrivalTime;
			return SortBy.order === "des" ? nextArrival - prevArrival : prevArrival - nextArrival;
		} else if (SortBy.type === "duration") {
			return SortBy.order === "des" ? next.duration - prev.duration : prev.duration - next.duration;
		}
	};

	const filterFlights = (flight) => {
		if (!flight.fares.length) {
			return false;
		}
		const _stops = flight.onwardSegments.length - 1;
		const _inPolicy = true;
		const _fullRefundable = false;
		const _airline = flight.onwardSegments[0].airlineName;
		const _time = classifyTime(flight.onwardSegments[0].departureTime);
		const _price = flight.fares[0].price;

		const { maxPrice, airlines, stops, time, inPolicy, fullRefundable } = filters;

		let show = true;

		if (maxPrice && _price > maxPrice) {
			show = false;
		}
		if (stops.length && !stops.includes(_stops)) {
			show = false;
		}
		if (inPolicy && !_inPolicy) {
			show = false;
		}
		if (fullRefundable && !_fullRefundable) {
			show = false;
		}
		if (airlines.length && !airlines.includes(_airline)) {
			show = false;
		}
		if (time.length && !time.includes(_time)) {
			show = false;
		}
		return show;
	};

	if (!flights.length)
		return (
			<div className="flex-col w-full py-20 flex-center">
				<h1 className="mb-12 text-2xl text-center">No Flights found</h1>
			</div>
		);

	return (
		<>
			<HasApproval approval={approval} />
			<UserChoice />
			<Sort sortBy={SortBy} updateSort={setSortBy} />
			{flights
				.filter(filterFlights)
				.sort(sortFlights)
				.map((flight, index) => {
					const { flightId, onwardSegments, returnSegments, fares, duration, onwardDuration, returnDuration, inPolicy } = flight;
					const cheapestFare = fares.reduce((prev, current) => (prev.price < current.price ? prev : current));
					const highestFare = fares.reduce((prev, current) => (prev.price > current.price ? prev : current));
					if (!inPolicy && showOutOfPolicyOptions === false) {
						return null;
					}

					return (
						<div key={index} className="grid grid-cols-3 mt-4 border rounded-md shadow-sm bg-canvas">
							<div className="col-span-2 divide-y">
								<FlightCard key={index} {...flight} />
							</div>
							<div className="flex h-full gap-x-2">
								{flight?.fares.map((fare, i) => {
									return <FareCard approval={approval} key={i} selected={SelectedFlight} onSelect={(selectedFare) => handleSelect(selectedFare, flight)} {...{ flight, fare, cheapestFare, highestFare }} />;
								})}
							</div>
						</div>
					);
				})}
		</>
	);
}

export default FlightResult;
