import React, { useEffect, useState } from "react";
import { FlightFilters, FlightSearch, Weather } from "../../../../../../Components";
import { Loader, SidePaneContent, Toast } from "../../../../../../Components";
import FlightResult from "./Result";
import Summary from "./Result/Components/summary";
import { useSearchParams } from "react-router-dom";
import { apis, get } from "../../../../../../Utils/axios";
import CloudsVideo from "../../../../../../Assets/Videos/clouds.mp4";

function OnewaySRP() {
	const [searchParams] = useSearchParams();
	const from = searchParams.get("from");
	const to = searchParams.get("to");
	const fromCode = searchParams.get("fromCode");
	const fromCity = searchParams.get("fromCity");

	const toCode = searchParams.get("toCode");
	const toCity = searchParams.get("toCity");
	const date = parseInt(searchParams.get("date"));
	const adult = parseInt(searchParams.get("adult")) || 1;
	const child = parseInt(searchParams.get("child")) || 0;
	const infant = parseInt(searchParams.get("infant") || 0);
	const cabinClass = searchParams.get("cabinClass") || "1";
	const requestId = searchParams.get("requestId") || "";
	const approvalId = searchParams.get("approvalId") || "";
	const [Loading, setLoading] = useState(true);
	const [SelectedFlight, setSelectedFlight] = useState();
	const [Result, setResult] = useState({
		flights: [],
		journeyKey: "",
		currency: "",
	});

	const [Filters, setFilters] = useState({
		maxPrice: 0,
		stops: [],
		airlines: [],
		time: [],
		inPolicy: false,
		fullyRefundable: false,
	});

	useEffect(() => {
		searchFlights({ fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId });
	}, [fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId]);

	const searchFlights = ({ fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId }) => {
		setLoading(true);
		setSelectedFlight();
		get(apis.getOneWayFlights, { from: fromCode.toUpperCase(), to: toCode.toUpperCase(), date, adult, child, infant, cabinClass, requestId, approvalId }, (r, e) => {
			if (r) {
				setResult(r);
				setLoading(false);
			} else if (e) {
				Toast.handleError(e);
				setLoading(false);
			}
		});
	};

	return (
		<>
			<Loader loading={Loading} />
			<SidePaneContent>
				{SelectedFlight ? (
					<div className="h-full">
						<Summary {...{ approval: Result.approval, passengers: { adult, child, infant }, selectedFlight: SelectedFlight, approvalId, requestId, fromCode, toCode, date, adult, child, infant, cabinClass }} />
					</div>
				) : (
					<div className="h-full p-4">
						<FlightFilters currency={Result.currency} flights={Result.flights} filters={Filters} updateFilters={setFilters} />
					</div>
				)}
			</SidePaneContent>

			<div className="relative p-4">
				<FlightSearch from={{ airportName: from, airportCode: fromCode, cityName: fromCity }} to={{ airportName: to, airportCode: toCode, cityName: toCity }} date={date} passengers={{ adult, child, infant }} cabinClass={{ value: cabinClass }} hideModes />
				{Loading ? (
					<div className="w-full mt-20 flex-center">
						<div>
							<span className="text-sm text-secondary">Hang tight, getting the best fares for you ...</span>
							<Weather place={toCity} className="mt-6" />
						</div>
					</div>
				) : (
					<FlightResult approval={Result.approval} filters={Filters} flights={Result.flights} onSelect={setSelectedFlight} SelectedFlight={SelectedFlight} />
				)}
			</div>
			{(Loading || !Result.flights.length) && (
				<>
					<video className="absolute bottom-0 left-0 object-cover w-full h-full -z-10" autoPlay muted loop>
						<source src={CloudsVideo} type="video/mp4" />
					</video>
					<div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full -z-10" style={{ background: "linear-gradient(to bottom, rgba(255,255,255,1) 70%,rgba(255,255,255,0) 100%)" }} />
					<div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full -z-10" style={{ background: "linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 20%)" }} />
					<div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full -z-10" style={{ background: "linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 20%)" }} />
				</>
			)}
		</>
	);
}

export default OnewaySRP;
