import React from "react";
import { FaPlane } from "react-icons/fa";
import { MdLuggage } from "react-icons/md";

function FlightDetails({ baggage }) {
	return (
		<>
			<h6>Flight Details</h6>
			<div className="mt-2 align-center">
				<div className="w-8 h-8 mr-2 rounded-sm bg-light flex-center">
					<MdLuggage />
				</div>
				<div>
					<h6>Check in Baggage</h6>
					<div className="text-xs text-secondary">{baggage.checkin}</div>
				</div>
			</div>
			<div className="mt-2 align-center">
				<div className="w-8 h-8 mr-2 rounded-sm bg-light flex-center">
					<FaPlane />
				</div>
				<div>
					<h6>Cabin Baggage</h6>
					<div className="text-xs text-secondary">{baggage.cabin}</div>
				</div>
			</div>
		</>
	);
}

export default FlightDetails;
