import { useMemo, useState } from "react";
import React from "react";
import { Transition, Combobox } from "@headlessui/react";
import { Fragment } from "react";
import { apis, get } from "../../../Utils/axios";
import { Toast, Loader } from "../..";
import { debounce } from "../../../Utils/utils";

function AirportSearch({ icon, label = "", placeholder = "", onChange = () => {}, onSelect = () => {}, selected }) {
	const [Loading, setLoading] = useState();
	const [Result, setResult] = useState([]);
	const [query, setQuery] = useState("");

	const searchAirport = (search) => {
		if (search && search.length > 2) {
			get(apis.searchAirports, { search }, (r, e) => {
				if (r) {
					setResult(r.airports);
					setLoading(false);
				} else if (e) {
					Toast.handleError(e);
					setLoading(false);
				}
			});
		}
	};

	const debouncedSearch = useMemo(() => debounce(searchAirport), []);

	const handleChange = (query) => {
		if (query) {
			setQuery(query);
			onChange(query);
			setLoading(true);
			debouncedSearch(query);
		}
	};

	const handleSelect = (option) => {
		onSelect(option);
	};
	return (
		<Combobox as="div" value={selected} onChange={handleSelect} className="combobox">
			{label && <Combobox.Label className="block mb-2">{label}</Combobox.Label>}
			<div className="relative">
				<Combobox.Input autoComplete="off" placeholder={placeholder} onChange={(event) => handleChange(event.target.value)} displayValue={(option) => option.airportName} className={`input ${icon ? " indent-6" : ""}`} />
				<div className="absolute top-0 h-full left-3 flex-center">{icon}</div>
			</div>
			<Transition afterLeave={() => setQuery("")}>
				<Combobox.Options className="options">
					{Loading ? (
						<div className="p-2 flex-center">
							<Loader className="rounded-full" loading={true} />
						</div>
					) : Result?.length === 0 && query !== "" ? (
						<div className="relative p-3 text-xs text-gray-700 cursor-default select-none">Nothing found for "{query}"</div>
					) : (
						Result?.map((option, i) => (
							<Combobox.Option key={i} value={option} as={Fragment} className="item">
								{({ active, selected }) => (
									<li className={`${active ? "bg-light" : "bg-white"} border-b`}>
										<div className="align-center">
											<div className={`rounded-lg p-2 transition-all duration-200 ${active ? "bg-primary text-white" : "bg-dark text-white"} text-xs font-bold mr-3 uppercase`}>{option.airportCode}</div>
											<div className="text-xs capitalize">{option.airportName}</div>
										</div>
									</li>
								)}
							</Combobox.Option>
						))
					)}
				</Combobox.Options>
			</Transition>
		</Combobox>
	);
}

export default AirportSearch;
