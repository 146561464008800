import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Flights from "./Flights";
import OnewaySRP from "./Flights/Oneway";
import RoundSRP from "./Flights/Round";
import BookFlight from "./Flights/Book";
import ConfirmFlight from "./Flights/Confirm";
import Stays from "./Stays";
import StaysSRP from "./Stays/SRP";
import HotelPage from "./Stays/HotelPage";
import BookStay from "./Stays/Book";
import ConfirmStay from "./Stays/Confirm";
import Buses from "./Buses";
import BusesSRP from "./Buses/SRP";
import BookBus from "./Buses/Book";
import ConfirmBus from "./Buses/Confirm";
import FlightHome from "./Flights";

function Travel() {
	return (
		<Routes>
			<Route path="/buses/confirm" element={<ConfirmBus />} />
			<Route path="/buses/book" element={<BookBus />} />
			<Route path="/buses/search" element={<BusesSRP />} />
			<Route path="/buses" element={<Buses />} />

			<Route path="/flights/book" element={<BookFlight />} />
			<Route path="/flights/confirm" element={<ConfirmFlight />} />
			<Route path="/flights/round" element={<RoundSRP />} />
			<Route path="/flights/oneway" element={<OnewaySRP />} />
			<Route path="/flights" element={<Flights />} />

			<Route path="/stays/book" element={<BookStay />} />
			<Route path="/stays/confirm" element={<ConfirmStay />} />
			<Route path="/stays/hotel" element={<HotelPage />} />
			<Route path="/stays/search" element={<StaysSRP />} />
			<Route path="/stays" element={<Stays />} />

			<Route path="/" element={<FlightHome />} />
			{/* <Route path="/" element={<Home />} /> */}
		</Routes>
	);
}

export default Travel;
