import React, { useEffect, useRef, useState } from "react";
import { useUserPreference } from "../../../../../../Hooks/useUserPreference";
import FareSummary from "./FareSummary";
import { Loader, Steps, Toast } from "../../../../../../Components";
import ReviewStep from "./Steps/Review";
import Payment from "./Steps/payment";
import Travellers from "./Steps/travellers";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserConfig } from "../../../../../../Hooks/useUserConfig";
import { useClientSettings } from "../../../../../../Hooks/useClientSettings";
import moment from "moment";
import { passengerTypeFromDob } from "Utils/utils";

function BookFlight() {
	const NAVIGATE = useNavigate();
	const LOCATION = useLocation();
	const { checkUserPermission } = useUserConfig();
	// const { travelPolicy } = useUserPolicy();
	const { showSidePane } = useUserPreference();
	const { isEnabled } = useClientSettings();
	const isTA = checkUserPermission("TA");

	// const { adminBooking = false } = (travelPolicy?.domesticFlight?.adminBooking && !isTA) || {};
	const { flight, fareConfirmDetails, passengers, approvalId, requestId } = LOCATION.state || {};
	console.log(flight);

	//Check for direct page access
	if (!flight) {
		Toast.error("Try a different flight");
		NAVIGATE(-1);
	}

	// if (adminBooking) {
	// 	Toast.error("Self booking is not allowed");
	// 	NAVIGATE(-1);
	// }

	const GET_CUSTOM_FIELDS = useRef();
	const GO_NEXT = useRef();

	const [Flight, setFlight] = useState(
		flight || {
			holdId: "",
			segments: [],
		}
	);

	const { adult, child, infant } = passengers;
	const maxPassengers = adult + child + infant;
	const [SelectedAddons, setSelectedAddons] = useState({
		baggage: [],
		meal: [],
		seat: [],
	});
	const [Errors, setErrors] = useState({
		travellers: [],
	});
	console.log(Errors);

	const [SelectedTravellers, setSelectedTravellers] = useState([]);
	const [Loading, setLoading] = useState(false);
	const [CustomGSTIN, setCustomGSTIN] = useState("");
	const [CustomFieldsData, setCustomFieldsData] = useState({});
	const [CostCenter, setCostCenter] = useState();

	useEffect(() => {
		showSidePane(false);
		return () => {
			showSidePane(true);
		};
	}, []);

	const showError = (error) => {
		setErrors((prev) => ({ ...prev, travellers: [...prev.travellers, error] }));
	};

	const validateTravellers = (travellers, max) => {
		setErrors((prev) => ({ ...prev, travellers: [] }));
		//1-2 infant
		//2-12 child
		//18+ adult
		const noOfAdults = travellers.filter(({ dob }) => passengerTypeFromDob(dob) === "ADULT").length;
		if (noOfAdults !== adult) {
			showError(`Add ${adult} adult travellers`);
			return false;
		}
		const noOfChildren = travellers.filter(({ dob }) => passengerTypeFromDob(dob) === "CHILD").length;
		if (noOfChildren !== child) {
			showError(`Add ${child} children`);
			return false;
		}
		const noOfInfants = travellers.filter(({ dob }) => passengerTypeFromDob(dob) === "INFANT").length;
		if (noOfInfants !== infant) {
			showError(`Add ${infant} infants`);
			return false;
		}

		const { dobValidation, extraBaggage, gst, meal, nationalityValidation, passportValidation, perPerson, seat } = fareConfirmDetails.mandatoryField;
		let valid = true;
		travellers.forEach((traveller) => {
			const { identification, phone, fName, gender, dob } = traveller || {};
			if (!phone) {
				showError(`${fName} does not have phone number`);
				valid = false;
			}
			if (!gender) {
				showError(`${fName} does not have gender specified`);
				valid = false;
			}
			if (passportValidation && (!identification?.passportNumber || !identification?.passportExpireDate || !identification?.passportIssueDate || !identification?.passportCountry)) {
				showError(`${fName} have incomplete passport details`);
				valid = false;
			}
			if (dobValidation && !dob) {
				showError(`${fName} does not have date of birth specified`);
				valid = false;
			}
			if (nationalityValidation && !identification?.nationality) {
				showError(`${fName} does not have nationality specified`);
				valid = false;
			}
		});
		if (travellers.length < max) {
			showError(`Please select all the travellers`);
			valid = false;
		}

		return valid;
	};

	// const updateAddons = ({ baggage, meal, seat }) => {
	// 	const { segments: onwardSegments, returnSegments } = Flight;
	// 	let addons = [
	// 		{
	// 			from: onwardSegments[0].departureCode,
	// 			to: onwardSegments[onwardSegments.length - 1].arrivalCode,
	// 			baggage: [],
	// 			segments: onwardSegments.map(({ departureCode, arrivalCode }) => ({
	// 				from: departureCode,
	// 				to: arrivalCode,
	// 				seat: [],
	// 				meal: [],
	// 			})),
	// 		},
	// 	];
	// 	//Return Journey Optional
	// 	if (returnSegments?.length > 0) {
	// 		addons.push({
	// 			from: returnSegments[0].departureCode,
	// 			to: returnSegments[returnSegments.length - 1].arrivalCode,
	// 			baggage: [],
	// 			segments: returnSegments.map(({ departureCode, arrivalCode }) => ({
	// 				from: departureCode,
	// 				to: arrivalCode,
	// 				seat: [],
	// 				meal: [],
	// 			})),
	// 		});
	// 	}

	// 	for (const flight of addons) {
	// 		const originDestination = `${flight.from} to ${flight.to}`;
	// 		baggage.forEach(({ code, count, isInclusiveFreeBaggage, label, journey }) => {
	// 			if (journey === originDestination) {
	// 				for (let j = 1; j <= count; j++) {
	// 					flight.baggage.push({
	// 						code,
	// 						info: label,
	// 						isInclusiveFreeBaggage,
	// 					});
	// 				}
	// 			}
	// 		});
	// 		meal.forEach(({ code, count, description, segment, journey }) => {
	// 			if (journey === originDestination) {
	// 				for (const flightSegment of flight.segments) {
	// 					const segmentFromTo = `${flightSegment.from} to ${flightSegment.to}`;
	// 					if (segmentFromTo === segment) {
	// 						for (let j = 1; j <= count; j++) {
	// 							flightSegment.meal.push({
	// 								code: code,
	// 								info: description,
	// 							});
	// 						}
	// 					}
	// 				}
	// 			}
	// 		});
	// 		seat.forEach(({ rowId, columnId, segment, journey }) => {
	// 			if (journey === originDestination) {
	// 				for (const flightSegment of flight.segments) {
	// 					const segmentFromTo = `${flightSegment.from} to ${flightSegment.to}`;
	// 					if (segmentFromTo === segment) {
	// 						flightSegment.seat.push({
	// 							columnId,
	// 							rowId,
	// 						});
	// 					}
	// 				}
	// 			}
	// 		});
	// 	}
	// 	setLoading(true);
	// 	post(apis.updateAddons, { holdId: Flight.holdId, addons }, (r, e) => {
	// 		if (r) {
	// 			setFlight(r);
	// 			GO_NEXT.current();
	// 			setLoading();
	// 		} else if (e) {
	// 			Toast.handleError(e);
	// 			setLoading();
	// 		}
	// 	});
	// };

	const handleStep = (step) => {
		if (step === 2) {
			if (!CostCenter && isEnabled("COST_CENTER") && !isTA) {
				Toast.error("Cost center is required");
				return false;
			}
			const customFields = GET_CUSTOM_FIELDS.current();
			if (!customFields) {
				//Required custom fields not filled
				return false;
			}
			setCustomFieldsData(customFields);
			if (CustomGSTIN && CustomGSTIN.length < 15) {
				Toast.error("Enter valid GSTIN");
				return false;
			}
		}
		if (step === 3) {
			if (!validateTravellers(SelectedTravellers, maxPassengers)) {
				return false;
			}
			return true;
		}
		return true;
	};

	return (
		<>
			<Loader loading={Loading} />
			<div className="grid grid-cols-4 p-4 gap-x-4">
				<div className="col-span-3">
					<Steps
						goNext={GO_NEXT}
						loading={Loading}
						onChange={handleStep}
						steps={[
							{
								heading: "Review your flight",
								description: "Review your flight details",
								content: <ReviewStep setCostCenter={setCostCenter} costCenter={CostCenter} getCustomFields={GET_CUSTOM_FIELDS} gstin={CustomGSTIN} onGSTChange={setCustomGSTIN} flight={Flight} loading={Loading} />,
							},
							//For travel admin only
							{
								heading: `Travellers (${SelectedTravellers?.length} / ${maxPassengers})`,
								description: "Select Travellers for this booking",
								content: <Travellers errors={Errors.travellers} {...{ approvalId, requestId }} max={maxPassengers} passengers={SelectedTravellers} setPassengers={setSelectedTravellers} mandatoryField={fareConfirmDetails.mandatoryField} />,
							},
							// {
							// 	heading: "Addons",
							// 	description: "Add meals, Seat choice, extra baggage",
							// 	content: <AddonStep selected={SelectedAddons} onSelect={setSelectedAddons} travellers={SelectedTravellers} holdId={Flight.holdId} />,
							// },
							{
								heading: "Payment",
								description: `Pay ${Flight?.selectedFare?.currency} ${Flight?.selectedFare?.price} to completed booking`,
								content: (
									<Payment
										fareConfirmDetails={fareConfirmDetails}
										CustomGSTIN={CustomGSTIN}
										flight={Flight}
										setFlight={setFlight}
										passengers={SelectedTravellers}
										costCenterId={CostCenter?.value}
										customFields={CustomFieldsData}
										amount={Flight?.selectedFare?.price}
										approvalId={approvalId}
										requestId={requestId}
									/>
								),
							},
						]}
					/>
				</div>
				<div className="col-span-1">
					<FareSummary selectedAddons={SelectedAddons} flight={Flight} />
				</div>
			</div>
		</>
	);
}

export default BookFlight;
