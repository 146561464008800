import React, { useEffect, useState } from "react";
import { apis, post } from "../../../../../../Utils/axios";
import { Button, Spinner, Toast } from "../../../../../../Components";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import moment from "moment";
import { useClientSettings } from "Hooks/useClientSettings";

function FareSummary({ hotelPolicy, setHotelPolicy, hotelId, bookingCode, roomCode, checkIn, checkOut, occupancy, hotel, room }) {
	const NAVIGATE = useNavigate();
	const { currency } = useClientSettings();
	const [Loading, setLoading] = useState(true);

	useEffect(() => {
		if (hotelId) {
			checkPolicy();
		}
	}, []);

	const checkPolicy = () => {
		setLoading(true);
		post(
			apis.getHotelPolicy,
			{
				hotelId,
				bookingCode,
				roomCode,
				checkIn,
				checkOut,
				occupancy,
			},
			(r, e) => {
				if (r) {
					setHotelPolicy(r);
					setLoading();
				} else if (e) {
					Toast.handleError(e);
					setLoading();
				}
			}
		);
	};

	const refundable = hotelPolicy.refundable === "REFUNDABLE";
	const nights = moment(checkOut).diff(moment(checkIn), "days");

	return (
		<>
			<Button onClick={() => NAVIGATE(-1)} variant="outlined" className="w-full mb-4">
				<FiArrowLeft className="mr-4" />
				Go Back to Search
			</Button>
			<div className="p-4 border rounded-md">
				<h3>Fare Summary</h3>
				<div className="mt-4 text-xs">
					{occupancy.length} Room x {nights} Night
				</div>
				<div className="mt-2 flex-center-between text-label">
					<div>Base Amount</div>
					<div className="text-end">
						{currency} {(room.amount - room.discount)?.toFixed(2)}
					</div>
				</div>
				<div className="mt-2 flex-center-between text-label">
					<div>Tax</div>
					<div className="text-end">
						{currency} {room.tax?.toFixed(2)}
					</div>
				</div>
				<div className="p-3 mt-4 font-bold rounded-lg flex-center-between bg-compliment text-accent">
					<div>Booking Amount</div>
					<div className="text-end">
						{currency} {(room.amount + room.tax - room.discount)?.toFixed(2)}
					</div>
				</div>
			</div>
			<div className="flex flex-col gap-4 p-4 mt-4 border rounded-md">
				{Loading ? (
					<div className="flex-center">
						<Spinner />
					</div>
				) : (
					<div>
						<h3>Cancellation</h3>
						<div className="mt-3 text-label">{hotelPolicy.cancelPolicy}</div>
					</div>
				)}
			</div>
		</>
	);
}

export default FareSummary;
