import React from "react";
import { Button, OutOfPolicyBadge } from "../../../../../../../../Components";
import { useUserConfig } from "../../../../../../../../Hooks/useUserConfig";

function Fare({ selected, flight, fare, cheapestFare, highestFare, onSelect, approval }) {
	const { checkUserPermission } = useUserConfig();
	const approvedAmount = approval?.amount;
	const { fareKey, price, refundable, inPolicy, violations, currency } = fare;
	const active = selected && selected.selectedFare.fareKey === fareKey;
	const askApproval = !inPolicy && !checkUserPermission("TA") && (approvedAmount ? price > Number(approvedAmount) : true);

	if (price > 0)
		return (
			<>
				<div className="flex-1 border-l flex-center">
					<div className="p-2 space-y-4 text-center">
						{askApproval ? <OutOfPolicyBadge violations={[...violations, ...flight.violations]} /> : <></>}
						<h2>
							{currency} {price}
						</h2>
						{onSelect &&
							(active ? (
								<Button onClick={() => onSelect()} className="w-full mt-2 btn-xs">
									Remove
								</Button>
							) : (
								<Button onClick={() => onSelect(fare)} variant="outlined" className="w-full mt-2 btn-xs">
									Select
								</Button>
							))}
					</div>
				</div>
			</>
		);
	return null;
}

export default Fare;
