import React from "react";
import { Button, ImportBooking, StayPreviewCard } from "../../../../..";
import "swiper/css";
import "swiper/css/navigation";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useClientSettings } from "Hooks/useClientSettings";

function StayRequest({ id, choices, status, onReload = () => {} }) {
	const { currency } = useClientSettings();
	const NAVIGATE = useNavigate();

	const searchStays = () => {
		const { hotel, checkIn, checkOut } = choices[0];
		NAVIGATE(`/app/travel/stays/search/?city=${hotel.locality?.localityName}&lat=${hotel.locality?.localityLatitude}&lng=${hotel.locality?.localityLongitude}&checkIn=${checkIn}&checkOut=${checkOut}&rooms=${JSON.stringify([{ adult: 1, child: 0 }])}&requestId=${id}`);
	};

	return (
		<>
			{status === "requested" && (
				<div className="flex gap-2">
					<ImportBooking onReload={onReload} type="stay" requestId={id} btnLabel="Import Booking" btnVariant="outlined" />
					<Button onClick={searchStays} variant="outlined" className="w-full shadow-sm">
						<FiSearch className="mr-2" />
						Search Stays
					</Button>
				</div>
			)}
			{choices.map((choice, i) => {
				const { hotel, room } = choice;
				return (
					<>
						<div key={i} className="p-4 rounded-lg shadow-sm bg-canvas">
							<h4>User's Choice</h4>
							<div className="flex flex-col mt-4 gap-y-2">
								<div className="flex-center-between">
									<label>Departure</label>
									<label>
										{currency} {room.amount}
									</label>
								</div>
								<StayPreviewCard hotel={hotel} room={room} />
							</div>
						</div>
					</>
				);
			})}
		</>
	);
}

export default StayRequest;
