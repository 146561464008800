import React from "react";
import { StampTo24hr } from "../../../../../../../Utils/utils";
import { FaBus, FaPlane } from "react-icons/fa";
import BookingStatus from "./Components/bookingStatus";
import { useClientSettings } from "Hooks/useClientSettings";

function BusBooking({ operator, rating, bus, from, to, departure, arrival, amount, date, mode, type, onSelect = () => {}, status, id, className = "" }) {
	const { currency } = useClientSettings();
	return (
		<div className="overflow-hidden border shadow-sm cursor-pointer rounded-xl bg-canvas min-w-min hover:shadow-md">
			<div className="p-4">
				<div className="flex-center-between">
					<h4>{operator.name}</h4>
					<div className="text-xs font-bold">
						{currency} {amount}
					</div>
				</div>
				<div className="flex mt-3 gap-x-4">
					<div>
						<div className="font-bold whitespace-nowrap">{StampTo24hr(departure.time)}</div>
						<div className="text-xs text-secondary">{departure.point}</div>
					</div>
					<div className="flex-grow w-24 flex-center">
						<div className="w-full">
							<div className="align-center">
								<div className="w-2 h-2 border rounded-full border-dark" />
								<div className="flex-grow border-t border-dotted border-dark" />
								<FaBus />
								<div className="flex-grow border-t border-dotted border-dark" />
								<div className="w-2 h-2 border rounded-full border-dark" />
							</div>
							<div className="mt-2 text-xs text-center whitespace-nowrap">2hr 30min</div>
						</div>
					</div>
					<div>
						<div className="font-bold whitespace-nowrap">{StampTo24hr(arrival.time)}</div>
						<div className="text-xs text-secondary">{arrival.point}</div>
					</div>
				</div>
			</div>
			{status && <BookingStatus status={status} id={id} />}
		</div>
	);
}

export default BusBooking;
