import React from "react";
import { FiArrowUpRight, FiFilter } from "react-icons/fi";
import { Searchbox, Checkbox, RangeInput } from "../../..";
import { useClientSettings } from "Hooks/useClientSettings";

function BusFilters() {
	const { currency } = useClientSettings();

	return (
		<div className="flex flex-col justify-between h-full">
			<div>
				<div className="p-3 text-xs font-bold rounded-md bg-compliment text-accent flex-center-between">
					View you Policy
					<FiArrowUpRight clas />
				</div>

				<div className="mt-6 align-center">
					<FiFilter className="mr-2" />
					<h5>Bus Filters</h5>
				</div>

				<div className="mt-6">
					<label>Search by Points</label>
					<Searchbox placeholder="Search by Board Point" cmd="b" className="mt-4" />
					<Searchbox placeholder="Search by Drop Point" cmd="d" className="mt-4" />
				</div>

				<div className="mt-6">
					<div className="text-sm font-semibold flex-center-between">
						<div className="mb-2">Price</div>
						<div className="font-bold text-dark">{currency} 500</div>
					</div>
					<RangeInput unit={currency} />
				</div>

				<div className="mt-6">
					<div className="mb-2 text-sm font-semibold">Bus Types</div>
					<div>
						<Checkbox label="Seater" className="mt-2 !text-xs" />
						<Checkbox label="Sleeper" className="mt-2" />
						<Checkbox label="AC" className="mt-2" />
						<Checkbox label="Non-AC" className="mt-2" />
					</div>
				</div>

				<div className="mt-6">
					<div className="mb-2 text-sm font-semibold">Departure Time</div>
					<div>
						<div className="mt-2 flex-center-between">
							<Checkbox label="Early Morning" />
							<div className="text-xs text-secondary">Midnight - 8AM</div>
						</div>
						<div className="mt-2 flex-center-between">
							<Checkbox label="Morning" />
							<div className="text-xs text-secondary">8AM - Noon</div>
						</div>
						<div className="mt-2 flex-center-between">
							<Checkbox label="Afternoon" />
							<div className="text-xs text-secondary">Noon - 4PM</div>
						</div>
						<div className="mt-2 flex-center-between">
							<Checkbox label="Evening" />
							<div className="text-xs text-secondary">4PM - 8PM</div>
						</div>
						<div className="mt-2 flex-center-between">
							<Checkbox label="Night" />
							<div className="text-xs text-secondary">8PM - Midnight</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BusFilters;
