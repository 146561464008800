import React, { useState } from "react";
import Clouds from "../../Assets/Images/intros/clouds.png";
import Rainbow from "../../Assets/Images/intros/rainbow.png";
import { Button, Modal, Toast, Form } from "..";
import { apis, post } from "../../Utils/axios";
import { scheduleIntro } from "../../Utils/utils";
import { MdBolt } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";

function UpgradeToTeams({ open, onClose }) {
	const [Loading, setLoading] = useState(false);

	const enableTeams = () => {
		setLoading(true);
		post(apis.upgradePlan, {}, (r, e) => {
			setLoading(false);
			if (r) {
				scheduleIntro("/app/home");
				window.location.href = "/app/home";
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
			}
		});
	};

	return (
		<Modal open={open} onClose={onClose} contentClass="!max-w-[40rem]">
			<div className="p-6">
				<h1 className="text-3xl text-center">
					<MdBolt className="text-4xl inline-item" />
					Upgrade to Teams
				</h1>
				{/* <div className="p-6">
                    <img src={Travel} alt="Travel" className="max-w-[15rem] m-auto"/>
                </div> */}
				<div className="grid grid-cols-2 gap-6 mt-6">
					<div className="flex-col p-4 text-center border rounded-xl flex-center-between">
						<div>
							<img src={Clouds} alt="Clouds" className="h-40 m-auto" />
							<h2 className="mt-6">Basic</h2>
							<div className="text-label">Free of charge single user license, best suited for individual user</div>
							<div className="mt-4 text-3xl font-bold">Free</div>
							<div className="m-auto mt-4 text-sm">
								<div className="gap-2 align-center">
									<FiCheckCircle />
									Book travel for yourself
								</div>
								<div className="gap-2 align-center">
									<FiCheckCircle />
									Add and export your expenses
								</div>
								<div className="gap-2 align-center">
									<FiCheckCircle />
									Create Trips
								</div>
							</div>
						</div>
						<div className="w-full">
							<Button variant="outlined" className="w-full mt-4 btn-sm">
								Current Plan
							</Button>
						</div>
					</div>
					<div className="relative p-4 overflow-hidden text-center border rounded-xl">
						<div className="absolute top-0 bottom-0 left-0 right-0 gradient-glass -z-10" />
						<img src={Rainbow} alt="Rainbow" className="h-40 m-auto floating" />
						<h2 className="mt-6">Teams</h2>
						<div className="text-label">For companies with multiple users and teams, pay per user as you go</div>
						<div className="mt-4 text-3xl font-bold">Pro Plan</div>
						<div className="m-auto mt-4 text-sm">
							<div className="gap-2 align-center">
								<FaCheckCircle />
								Invite and manage Users
							</div>
							<div className="gap-2 align-center">
								<FaCheckCircle />
								Create Offices, Teams
							</div>
							<div className="gap-2 align-center">
								<FaCheckCircle />
								Enforce Policies
							</div>
							<div className="gap-2 align-center">
								<FaCheckCircle />
								Create Approval flows
							</div>
							<div className="gap-2 align-center">
								<FaCheckCircle />
								Cost Centers
							</div>
							<div className="gap-2 align-center">
								<FaCheckCircle />
								Automatic Payouts
							</div>
						</div>
						<Button loading={Loading} onClick={enableTeams} variant="primary" className="w-full mt-4 btn-sm">
							Upgrade to Pro
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default UpgradeToTeams;
