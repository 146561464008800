import React from "react";
import { Approvers, CopyValue, Disclosure, LabelValue, Violations } from "../..";
import { StampToDate } from "../../../Utils/utils";
import StatusIndicator from "../Components/status";
import EmployeeInfo from "../../Cards/EmployeeInfo";
import { useClientSettings } from "Hooks/useClientSettings";

function RequestDetails({ id, date, amount, requestor, approvers, violations, type, status, request }) {
	const { currency } = useClientSettings();

	return (
		<>
			<div className="p-4 rounded-lg shadow-sm bg-canvas">
				<Disclosure defaultOpen={true} heading={<h4>Request Details</h4>}>
					<div className="grid grid-cols-2 gap-4 mt-4">
						<LabelValue label="Request ID">
							<CopyValue>APP-{id}</CopyValue>
						</LabelValue>
						<LabelValue label="Requested on">{StampToDate(date)}</LabelValue>
						<LabelValue label="Requested Amount">
							{currency} {amount}
						</LabelValue>
						<LabelValue label="Request Status">
							<StatusIndicator status={status} />
						</LabelValue>
					</div>
				</Disclosure>
			</div>
			<div className="p-4 rounded-lg shadow-sm bg-canvas">
				<Disclosure defaultOpen={true} heading={<h4>Requested By</h4>}>
					<EmployeeInfo employee={requestor} className="mt-2" />
				</Disclosure>
			</div>
			<div className="p-4 rounded-lg shadow-sm bg-canvas">
				<Disclosure defaultOpen={true} heading={<h4>Approvers</h4>}>
					<Approvers approvers={approvers} />
				</Disclosure>
			</div>
			<Violations violations={violations} />
		</>
	);
}

export default RequestDetails;
