import React from "react";
import { BottomBar, Button, Container, Pagination, Searchbox, Table, AddGuest, EditPassport, EditNationalId, Toast, Dialog } from "../../../../../Components";
import { useState } from "react";
import useFetch from "../../../../../Hooks/useFetch";
import apis from "../../../../../Utils/apis";
import { StampToDate } from "../../../../../Utils/utils";
import { deleteRequest } from "../../../../../Utils/axios";
import { FaTrashAlt } from "react-icons/fa";

function Guests() {
	const initial = { guests: [], count: 0, pages: 0 };
	const [Query, setQuery] = useState({ search: "", page: 1 });
	const { response, reload, loading } = useFetch(apis.getAllGuests, Query, initial);
	const [ConfirmDialog, setConfirmDialog] = useState();

	const deleteGuest = () => {
		deleteRequest(`guests/${ConfirmDialog.id}`, {}, (r, e) => {
			if (r) {
				Toast.success(`Guest deleted`);
				setConfirmDialog();
				reload();
			} else if (e) {
				Toast.handleError(e);
			}
		});
	};

	return (
		<>
			<BottomBar>
				<div className="w-full flex-center-between">
					<div className="align-center gap-x-4">
						<AddGuest onReload={reload} />
						<h5>{response.guests?.length} Guests</h5>
					</div>
					<div>
						<Pagination active={Query.page} total={response.pages} onChange={(page) => setQuery({ ...Query, page })} />
					</div>
				</div>
			</BottomBar>
			<div className="mt-6 flex-center-between">
				<div className="align-center">
					<Searchbox placeholder="Search Users" cmd="s" onChange={(search) => setQuery({ ...Query, search })} />
				</div>
			</div>
			<Container variant="outlined" className="!p-0 mt-4">
				<Table
					loading={loading}
					className="rounded-md"
					nodes={response.guests.map((guest) => ({
						name: `${guest.fName} ${guest.lName}`,
						email: guest.email,
						phone: guest.phone,
						gender: guest.gender,
						dob: StampToDate(guest.dob),
						passport: <EditPassport reload={reload} identification={guest.identification} guestId={guest.id} photo={guest.photo} />,
						nationalIdNumber: <EditNationalId reload={reload} identification={guest.identification} guestId={guest.id} />,
						actions: (
							<Button onClick={() => setConfirmDialog(guest)} variant="danger" className="btn-xs">
								<FaTrashAlt />
							</Button>
						),
					}))}
					headers={[
						{ key: "name", label: "Guest Name" },
						{ key: "email", label: "Email" },
						{ key: "phone", label: "Phone" },
						{ key: "gender", label: "Gender" },
						{ key: "dob", label: "Date of Birth" },
						{ key: "passport", label: "Passport" },
						{ key: "nationId", label: "National ID" },
						{ key: "actions", label: "Actions" },
					]}
				/>
			</Container>
			<Dialog variant="danger" open={ConfirmDialog} title="Delete Guest" description="Are you sure you want to delete this guest, this is an irreversible action." submitLabel="Delete" onSubmit={deleteGuest} onClose={setConfirmDialog} />
		</>
	);
}

export default Guests;
