import React from "react";
import { Popup } from "../../../../../../Components";
import FareRules from "../SRP/Result/Components/cancellation";
import { useClientSettings } from "Hooks/useClientSettings";

function FareSummary() {
	const { currency } = useClientSettings();
	return (
		<>
			<h3>Fare Summary</h3>
			<Popup button="Fare Rules" btnClass="text-xs" contentClass="p-4 !max-w-xs">
				<FareRules />
			</Popup>

			<div className="mt-4 text-xs">Base Fare</div>
			<div className="mt-2 text-xs flex-center-between text-secondary">
				<div>1 Adult</div>
				<div className="text-end">{currency} 800</div>
			</div>

			<div className="mt-3 text-xs">Fee</div>
			<div className="mt-2 text-xs flex-center-between text-secondary">
				<div>Platform fee</div>
				<div className="text-end">{currency} 120</div>
			</div>
			<div className="mt-2 text-xs flex-center-between text-secondary">
				<div>Tax</div>
				<div className="text-end">{currency} 90</div>
			</div>
			<div className="p-3 mt-4 font-bold rounded-lg flex-center-between bg-compliment text-accent">
				<div>Booking Amount</div>
				<div className="text-end">{currency} 10,10</div>
			</div>
		</>
	);
}

export default FareSummary;
