import React from "react";
import { FaCar, FaRegClock } from "react-icons/fa";
import { StampTo24hr, StampToDate } from "../../../../../../Utils/utils";
import { useClientSettings } from "Hooks/useClientSettings";

function CabDetails({ id, pickupTime, amount, pickup, drop, variant, type, bookingId, booking, status, adminRequest }) {
	const { currency } = useClientSettings();
	return (
		<>
			<div className="flex-center-between">
				<h4 className="gap-2 capitalize align-center">
					<FaCar />
					{type}
				</h4>
				<div className="gap-2 font-bold align-center">
					<FaRegClock />
					{StampTo24hr(pickupTime)} {StampToDate(pickupTime)}
				</div>
				{/* <div className="text-xs font-bold">{currency} {amount}</div> */}
			</div>
			<div className="flex mt-3 gap-x-4">
				<div className="text-label max-w-[10rem]">{pickup}</div>
				<div className="flex-grow w-24 flex-center">
					<div className="w-full">
						<div className="align-center">
							<div className="w-2 h-2 border rounded-full border-dark" />
							<div className="flex-grow border-t border-dotted border-dark" />
							<FaCar />
							<div className="flex-grow border-t border-dotted border-dark" />
							<div className="w-2 h-2 border rounded-full border-dark" />
						</div>
						<div className="mt-1 text-center uppercase text-label">{variant}</div>
					</div>
				</div>
				<div className="text-label text-right max-w-[10rem]">{drop}</div>
			</div>
		</>
	);
}

export default CabDetails;
