import { useEffect, useState } from "react";
import { get } from "../Utils/axios";
import { removeNullKeys } from "../Utils/utils";

function useFetch(url, payload, initialState, cb) {
	const [Response, setResponse] = useState(initialState);
	const [Loader, setLoader] = useState(true);
	const [Error, setError] = useState();

	useEffect(() => {
		getRequest(url, payload, cb);
		// eslint-disable-next-line
	}, [url, payload]);

	const getRequest = (url, payload, cb) => {
		setLoader(true);
		get(url, payload, (r, e) => {
			if (r) {
				setResponse(removeNullKeys(r));
				setLoader(false);
				cb && cb(r, e);
			} else if (e) {
				// Toast.handleError(e);
				setError(e.response?.data?.error);
				setLoader(false);
				cb && cb({}, e);
			}
		});
	};

	return {
		response: Response,
		loading: Loader,
		error: Error,
		reload: () => getRequest(url, payload, cb),
	};
}

export default useFetch;
