import React from "react";
import { Badge, CopyValue } from "../../../..";
import StatusIndicator from "../../Components/status";
import { FaInfoCircle } from "react-icons/fa";

function BookingDetails({ pnr = [], amount, id, status, payment, currency, bookRefNo = [] }) {
	let totalRefund = payment?.refundBreakup?.reduce((prev, curr) => prev + curr.total, 0);

	if (status === "FAILED") {
		return (
			<div className="pb-6 flex-center">
				<Badge variant="danger">This booking has failed</Badge>
			</div>
		);
	}
	if (status === "PENDING") {
		return (
			<div className="pb-6 flex-center">
				<Badge variant="warning">Booking still in process... This may take a few minutes</Badge>
			</div>
		);
	}

	return (
		<>
			<div className="relative my-2 border border-dashed">
				<div className="absolute w-8 h-8 rounded-full -left-4 -top-4 bg-light z-1" />
				<div className="absolute w-8 h-8 rounded-full -right-4 -top-4 bg-light z-1" />
			</div>
			{status === "CANCELLED" && (
				<div className="pt-6 flex-center">
					<Badge variant="warning">
						<FaInfoCircle className="mr-1" />
						Refund in process... Details will updated shortly
					</Badge>
				</div>
			)}
			<div className="grid grid-cols-2 gap-4 p-6">
				<div className="text-xs font-semibold">
					<div className="text-label">Booking ID</div>
					<div>AIR-{id}</div>
				</div>
				<div className="text-xs font-semibold">
					<div className="text-label">Booking Amount</div>
					<div>
						{currency} {amount}
					</div>
				</div>
				{pnr.map((pnr) => {
					return (
						<div className="text-xs font-semibold">
							<div className="text-label">PNRs</div>
							<CopyValue>{pnr}</CopyValue>
						</div>
					);
				})}
				{bookRefNo?.map((refNo) => {
					return (
						<div className="text-xs font-semibold">
							<div className="text-label">Booking Ref No</div>
							<CopyValue>{refNo}</CopyValue>
						</div>
					);
				})}
				<div className="text-xs font-semibold">
					<div className="mb-1 text-label">Status</div>
					<StatusIndicator status={status} />
				</div>
				{status === "REFUNDED" && (
					<div className="text-xs font-semibold">
						<div className="text-label">Refunded Amount</div>
						<div>
							{currency} {totalRefund}
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default BookingDetails;
