import React, { useEffect, useState } from "react";
import { Button, Img, Loader, Popup, Toast, Modal } from "../../..";
import { FiDownload, FiMap } from "react-icons/fi";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { TbCircleCheckFilled } from "react-icons/tb";
import { apis, get } from "../../../../Utils/axios";
import moment from "moment";
import { StampToDate } from "../../../../Utils/utils";
import BookedFor from "./bookedFor";
import PlaceholderImage from "../../../../Assets/Images/placeholders/stay.jpg";
import { useClientSettings } from "Hooks/useClientSettings";

function StayBooking({ bookingId, onClose = () => {} }) {
	const { currency } = useClientSettings();
	const [Loading, setLoading] = useState(true);
	const [Booking, setBooking] = useState({});
	const { id, photo, name, address, hotel, rooms, status, amount, checkIn, checkOut, guests } = Booking;

	useEffect(() => {
		if (bookingId) {
			fetchBooking(bookingId);
		}
	}, [bookingId]);

	const fetchBooking = (bookingId) => {
		setLoading(true);
		get(apis.getStayBookingDetails, { bookingId }, (r, e) => {
			if (r) {
				setBooking(r);
				setLoading(false);
			} else if (e) {
				Toast.handleError(e);
				setLoading(false);
			}
		});
	};

	const launchMaps = () => {
		window.open(`https://www.google.com.sa/maps/search/${hotel?.locality?.latitude},${hotel?.locality?.longitude}`, "_blank");
	};

	const nights = moment(checkOut).diff(moment(checkIn), "days");

	return (
		<Modal open={bookingId} onClose={onClose} contentClass="!max-w-[40%]">
			<Loader loading={Loading} />
			<div className="p-4 rounded-lg bg-light">
				<div className="rounded-lg shadow-md bg-canvas">
					<div className="p-6">
						<div className={`flex gap-x-4 relative`}>
							<Swiper slidesPerView={"auto"} onSlideChange={(i) => {}} modules={[Navigation]} navigation={true} className="rounded-lg swiper-carousel">
								<SwiperSlide>
									<Img placeholder={PlaceholderImage} src={photo} className="h-full w-26" />
								</SwiperSlide>
							</Swiper>
							<div className="flex-grow px-2">
								<div className="flex gap-x-2">
									{hotel?.amenities?.length > 0 && (
										<Popup button="Amenities" btnClass="btn btn-outlined btn-xs">
											<div className="p-4">
												{hotel?.amenities.map((amenity, i) => {
													const { category, amenities } = amenity;
													return (
														<div key={i} className="mb-6">
															<h4 className="mb-2">{category}</h4>
															<div className="flex flex-wrap gap-2">
																{amenities.map((amenity, i) => (
																	<div key={i} className="chip">
																		{amenity}
																	</div>
																))}
															</div>
														</div>
													);
												})}
											</div>
										</Popup>
									)}
									{hotel?.locality && (
										<Button onClick={launchMaps} variant="outlined" className="btn-xs">
											<FiMap className="mr-1" />
											Map
										</Button>
									)}
								</div>

								<div className="mt-2 font-semibold text-ellipsis">{name}</div>
								<div className="text-sm text-secondary text-ellipsis">{address}</div>
								<div className="mt-2 flex-center-between">
									<div className="text-sm">
										{[...Array(parseInt(hotel?.starRating || 0))].map((e, i) => {
											return <span key={i}>⭑</span>;
										})}
									</div>
								</div>
							</div>
						</div>
						<div className="mt-6">
							{rooms?.map((room, i) => (
								<div key={i}>
									<h3>{room.roomName}</h3>
									{room.option?.package.map((item, i) => {
										return (
											<div key={i} className="mt-2 text-sm align-center text-secondary">
												<TbCircleCheckFilled className="mr-3 text-primary" /> {item}
											</div>
										);
									})}
								</div>
							))}
						</div>
					</div>
					<div className="relative my-2 border border-dashed">
						<div className="absolute w-8 h-8 rounded-full -left-4 -top-4 bg-light z-1" />
						<div className="absolute w-8 h-8 rounded-full -right-4 -top-4 bg-light z-1" />
					</div>
					<div className="grid grid-cols-2 gap-4 p-6">
						<div className="text-xs font-semibold">
							<div className="text-label">Booking ID</div>
							<div>STY-{id}</div>
						</div>
						<div className="text-xs font-semibold">
							<div className="text-label">Amount</div>
							<div>
								{currency} {amount}
							</div>
						</div>
						<div className="text-xs font-semibold">
							<div className="text-label">Stay Duration</div>
							<div>
								{nights} Night ({StampToDate(checkIn)} to {StampToDate(checkOut)})
							</div>
						</div>
						<div className="text-xs font-semibold">
							<div className="text-label">Guests</div>
							<div>{guests?.length} Adults</div>
						</div>
					</div>
				</div>
				<BookedFor type="stay" bookingId={bookingId} travellers={guests} onReload={() => fetchBooking(bookingId)} />
				<div className="flex mt-4 gap-x-2">
					<Button variant="outlined" className="flex-grow">
						<FiDownload className="mr-2" />
						Download Booking
					</Button>
					<Button variant="danger" className="flex-grow">
						Cancel Booking
					</Button>
				</div>
			</div>
		</Modal>
	);
}

export default StayBooking;
