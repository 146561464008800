import React, { useState } from "react";
import { AnimatedTick, Button, ViewBooking } from "../../../../../../Components";

function Success({ booking = {} }) {
	console.log(booking);

	const [BookingId, setBookingId] = useState();

	return (
		<div className="w-full h-full flex-center bg-light">
			<div className="p-8 border shadow-sm bg-canvas rounded-xl">
				<div className="h-20 flex-center">
					<AnimatedTick />
				</div>
				<div className="mt-4 text-center">
					<h1>Booking Confirmed</h1>
					<div className="mt-4 text-label">
						We are confirming your booking with the airline, <br />
						you can see your to know the status
					</div>
					<Button onClick={() => setBookingId(booking.otaTransactionId)} variant="outlined" className="m-auto mt-8">
						View Booking
					</Button>
					<ViewBooking id={BookingId} type="flight" onClose={() => setBookingId()} />
				</div>
			</div>
		</div>
	);
}

export default Success;
