import React, { useRef, useState } from "react";
import { Badge, Button, Modal, Razorpay, Toast } from "../../../../../../../Components";
import Eyes from "../../../../../../../Assets/Images/emojis/eyes.png";
import Thinking from "../../../../../../../Assets/Images/emojis/thinking.png";
import Tasty from "../../../../../../../Assets/Images/emojis/tasty.png";
import Visa from "../../../../../../../Assets/Images/icons/visa.png";
import MasterCard from "../../../../../../../Assets/Images/icons/mastercard.png";
import Upi from "../../../../../../../Assets/Images/icons/upi.png";
import { useNavigate } from "react-router-dom";
import FlightItinerary from "../../Round/Result/Components/itinerary";
import { apis, post } from "../../../../../../../Utils/axios";
import { useUserPolicy } from "../../../../../../../Hooks/useUserPolicy";

function Payment({ fareConfirmDetails, flight, passengers, customFields = {}, amount, approvalId, requestId, costCenterId }) {
	const NAVIGATE = useNavigate();
	const openPg = useRef();
	const [Loading, setLoading] = useState(false);
	const { travelPolicy } = useUserPolicy();
	const { enabled } = travelPolicy?.payment || { enabled: true }; //Enabled by default
	const [PriceCheckData, setPriceCheckData] = useState(null);

	const { segments, duration, returnSegments, returnDuration } = flight;

	const fareRecheck = () => {
		setLoading(true);
		post(
			apis.recheckFlightFare,
			{
				selectedFlightOptionKey: fareConfirmDetails?.selectedFlightOptionKey,
				travellers: passengers.map(({ id, isGuest }) => (isGuest ? { guestId: id } : { userId: id })),
			},
			(r, e) => {
				if (r) {
					setLoading();
					setPriceCheckData(r);
					//Proceed when no errors
					if (!r.error_code) {
						startBooking("WALLET", r);
					}
				} else if (e) {
					setLoading();
					Toast.handleError(e);
				}
			}
		);
	};

	const startBooking = (paymentMode, priceCheckData) => {
		const { logo, airlineName, airlineCode, flightNumber } = flight?.segments[0] || {};
		NAVIGATE(`/app/travel/flights/confirm`, {
			state: {
				priceCheckData,
				selectedFlightOptionKey: priceCheckData.selectedFlightOptionKey,
				logo,
				name: airlineName,
				code: `${airlineCode}-${flightNumber}`,
				approvalId,
				requestId,
				customFields,
				costCenterId,
				passengers: passengers.map(({ id, isGuest }) => (isGuest ? { guestId: id } : { userId: id })),
				paymentMode,
			},
		});
	};

	if (PriceCheckData?.priceDiffernce > 0) {
		const priceDiff = PriceCheckData.priceDiffernce;

		return (
			<Modal open={true}>
				<div className="p-6 text-center border rounded-md">
					<img src={priceDiff > 0 ? Thinking : Tasty} alt="Eyes Emoji" className="w-24 m-auto mb-6" />
					<h1>{priceDiff > 0 ? "Fare Changed !" : "More Savings !"}</h1>
					<div className="mt-2 text-sm">The fare for your booking has been changed. You can either continue with the new price or go back to search for a better deal.</div>
					<div className="gap-2 mt-6 flex-center">
						<Button loading={Loading} onClick={() => setPriceCheckData((prev) => ({ ...prev, diff: 0 }))} variant="primary" className="btn-sm">
							Continue with new Price
						</Button>
						{priceDiff > 0 && (
							<Button onClick={() => NAVIGATE(-1)} variant="dark" className="btn-sm">
								Go Back to Search
							</Button>
						)}
					</div>
				</div>
			</Modal>
		);
	}

	return (
		<>
			<div className="p-4 border rounded-md">
				<FlightItinerary onwardFlight={{ segments, duration }} returnFlight={{ segments: returnSegments, duration: returnDuration }} />
				<div className="mt-8">
					<h2>
						Your total amount is {flight?.selectedFare?.currency} {amount}
					</h2>
					<div className="mt-2 text-label">You can pay using Debit Card, Credit Card, Net Banking, UPI through secure payment gateway</div>
					<div className="gap-4 mt-4 align-center">
						<img src={Visa} alt="Visa" className="h-4" />
						<img src={MasterCard} alt="MasterCard" className="h-5" />
						<img src={Upi} alt="UPI" className="h-4" />
					</div>
				</div>
				{enabled ? (
					<div className="flex gap-2 mt-8">
						<Button loading={Loading} onClick={fareRecheck} variant="primary">
							Book Flight
						</Button>
					</div>
				) : (
					<Badge variant="danger mt-8">Payments are disabled for your account</Badge>
				)}
			</div>
			<Razorpay hideBtn openPg={openPg} onSuccess={(razorpayData) => startBooking("PG", razorpayData)} />
		</>
	);
}

export default Payment;
