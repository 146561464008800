import React, { useEffect, useState } from "react";
import { BottomBar, ViewApproval, ApprovalStatus, Table, Pagination, Approval, Searchbox, TableSwitch, CardsRenderer, Toast, TableCardRender, Button, Badge, InlineEmployee, CopyValue, ButtonGroup, DateRange } from "../../../../Components";
// import ApprovalFilters from "./filters";
import apis from "../../../../Utils/apis";
import { get } from "../../../../Utils/axios";
import { StampToDate } from "../../../../Utils/utils";
import moment from "moment";
import { FiEye } from "react-icons/fi";
import { useClientSettings } from "Hooks/useClientSettings";

function Pending() {
	const { currency } = useClientSettings();
	const [Status, setStatus] = useState({ label: "Pending", value: "pending" });
	const [OpenId, setOpenId] = useState();
	const [ViewMode, setViewMode] = useState();
	const initial = {
		approvals: [],
		count: 0,
		pages: 0,
	};
	const [Query, setQuery] = useState({
		search: "",
		page: 1,
		dates: {
			startDate: moment().subtract(30, "days").valueOf(),
			endDate: moment().add(30, "days").valueOf(),
		},
	});
	const [Result, setResult] = useState(initial);
	const [Loading, setLoading] = useState(true);

	useEffect(() => {
		fetchApprovals(Query, Status.value);
	}, [Query, Status]);

	const fetchApprovals = (query, status) => {
		setLoading(true);
		get(
			`/approval`,
			{
				...query,
				status,
			},
			(r, e) => {
				if (r) {
					setResult(r);
					setLoading(false);
				} else if (e) {
					Toast.handleError(e);
					setLoading(false);
				}
			}
		);
	};

	return (
		<>
			<div className="p-4 pb-24">
				<BottomBar>
					<div className="w-full flex-center-between">
						<h5>
							{Result.count} Approval {Status.label}
						</h5>
						<div>
							<Pagination active={Query.page} total={Result.pages} onChange={(page) => setQuery({ ...Query, page })} />
						</div>
					</div>
				</BottomBar>
				<div className="flex-center-between">
					<div className="align-center gap-x-2">
						<ButtonGroup
							onClick={setStatus}
							size="xs !p-1.5"
							selected={Status}
							className="text-sm"
							options={[
								{ label: "Pending", value: "pending" },
								{ label: "Approved", value: "approved" },
								{ label: "Rejected", value: "rejected" },
							]}
						/>
					</div>
					<div className="align-center gap-x-2">
						<Searchbox placeholder="Search approvals" cmd="s" onChange={(search) => setQuery({ ...Query, search })} />
						<TableSwitch mode={ViewMode} setMode={setViewMode} />
						{Status.value !== "pending" && <DateRange setRange={(dates) => setQuery({ ...Query, dates })} range={Query.dates} inputClass="!p-1.5 !text-xs" />}
						{/* <ApprovalFilters /> */}
					</div>
				</div>
				<TableCardRender
					loading={Loading}
					mode={ViewMode}
					table={
						<Table
							loading={Loading}
							className="mt-2 border rounded-md"
							nodes={Result.approvals.map((approval) => ({
								id: <CopyValue>APP-{approval.id}</CopyValue>,
								date: StampToDate(approval.date),
								type: <div className="capitalize">{approval.type}</div>,
								amount: `${currency} ${approval.amount}`,
								requestedBy: <InlineEmployee employee={approval.requestor} />,
								status: <ApprovalStatus status={approval.status} />,
								actions: (
									<Button onClick={() => setOpenId(approval.id)} variant="outlined" className="gap-2 btn-xs">
										<FiEye /> Open
									</Button>
								),
							}))}
							headers={[
								{ key: "id", label: "Approval ID" },
								{ key: "date", label: "Date" },
								{ key: "type", label: "Approval Type" },
								{ key: "amount", label: "Amount" },
								{ key: "requestedBy", label: "Requested By" },
								{ key: "status", label: "Status" },
								{ key: "actions", label: "Action" },
							]}
						/>
					}
					card={
						<CardsRenderer
							items={Result.approvals}
							render={(approval, i) => {
								return <Approval key={i} {...approval} onReload={() => fetchApprovals(Query, Status.value)} />;
							}}
						/>
					}
				/>
			</div>
			<ViewApproval approvalId={OpenId} onClose={() => setOpenId()} onReload={() => fetchApprovals(Query, Status.value)} />
		</>
	);
}

export default Pending;
