import React from "react";
import { Img } from "../..";

function ProductCard({ onClick = () => {}, id = "", name = "", description = "", price = 0, originalPrice = 0, photo = "" }) {
	return (
		<>
			<div onClick={onClick} className="overflow-hidden transition-all duration-300 border cursor-pointer rounded-xl hover:shadow-lg min-w-max">
				<div className="relative border-b">
					<Img src={photo} alt="" className="object-cover w-full h-32" />
				</div>
				<div className="p-3 text-sm">
					<h5>{name}</h5>
					<div className="text-label">{description}</div>
					<div className="mt-2 text-xs align-center gap-x-2">
						<div className="font-bold">{price}</div>
						<div className="line-through text-secondary">{originalPrice}</div>
						<div className="text-primary">20% Off</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ProductCard;
