import React, { useState } from "react";
import { Badge, Button, Dialog, Modal, Spinner, Toast } from "../../../../Library";
import { FiInfo } from "react-icons/fi";
import { apis, get, post } from "../../../../../Utils/axios";
import { FaUser } from "react-icons/fa";
import { StampToDate } from "../../../../../Utils/utils";
import { useClientSettings } from "Hooks/useClientSettings";

function FlightCancellation({ bookingId, onCancel }) {
	const { currency } = useClientSettings();
	const [Charges, setCharges] = useState();
	const [ShowCancellationRules, setShowCancellationRules] = useState();
	const [ConfirmDialog, setConfirmDialog] = useState();

	const fetchCancellationRules = () => {
		setShowCancellationRules(true);
		if (!Charges) {
			get(apis.getFlightCancellationCharges, { bookingId }, (r, e) => {
				if (r) {
					setCharges(r);
				} else if (e) {
					Toast.handleError(e);
				}
			});
		}
	};

	const cancelBooking = () => {
		setConfirmDialog();
		const hideToast = Toast.loading("Cancelling");
		post(
			apis.cancelFlightBooking,
			{
				bookingId,
			},
			(r, e) => {
				if (r) {
					hideToast();
					onCancel();
					setShowCancellationRules();
					setConfirmDialog();
				} else if (e) {
					hideToast();
					Toast.handleError(e);
				}
			}
		);
	};

	return (
		<>
			<Button onClick={fetchCancellationRules} variant="outlined" className="flex-grow">
				<FiInfo className="mr-2" />
				Check Cancellation
			</Button>
			<Modal open={ShowCancellationRules} onClose={setShowCancellationRules}>
				<div className="p-6">
					<h3>Cancellation Charges</h3>
					<div className="text-label">Please read the cancellation charges carefully before a cancellation request</div>
					{Charges ? (
						<>
							{Charges.map((flight, i) => {
								const { from, to, refundable, type, charges } = flight;
								if (type === "CANCEL") {
									return (
										<div key={i} className="mt-4 overflow-hidden border rounded-lg">
											<div className="p-4 flex-center-between bg-light">
												<h3>
													{from} → {to}
												</h3>
												{refundable ? <Badge variant="success">Refundable</Badge> : <Badge variant="danger">Non-Refundable</Badge>}
											</div>
											<div className="gap-2 p-4 text-sm font-bold align-center">
												<FaUser /> For Adult
											</div>
											<ul className="p-4 pt-0 text-xs list-disc list-inside">
												{charges.ADT?.map((slab, i) => {
													const { startTime, endTime, charge } = slab;
													return (
														<li key={i}>
															Cancellation charges of {currency} {charge} if cancelled between {StampToDate(startTime)} and {StampToDate(endTime)}
														</li>
													);
												})}
											</ul>
										</div>
									);
								}
								return null;
							})}
							<Button onClick={() => setConfirmDialog(true)} variant="danger" className="w-full mt-4 btn-sm">
								Cancel Booking
							</Button>
						</>
					) : (
						<Spinner className="m-auto" />
					)}
				</div>
			</Modal>
			<Dialog variant="danger" open={ConfirmDialog} title="Cancel Flight" description="Are you sure you want to cancel this flight booking." submitLabel="Cancel Booking" cancelLabel="Back" onSubmit={cancelBooking} onClose={setConfirmDialog} />
		</>
	);
}

export default FlightCancellation;
