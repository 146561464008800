import React, { useEffect, useState } from "react";
import { Form, Button, UploadPhoto, DatePicker, Toast, ButtonGroup } from "../../../../Components";
import moment from "moment";
import { FiArrowRight } from "react-icons/fi";
import { apis, patch } from "../../../../Utils/axios";
import { useLoginHook } from "../../../../Hooks/useLoginHook";

function VerifyDetails({ profile }) {
	const { logout } = useLoginHook();
	const [Loader, setLoader] = useState();
	const [Values, setValues] = useState({});

	useEffect(() => {
		//Prefilling whats already available
		if (profile) {
			setValues({
				gender: {
					label: profile.gender,
					value: profile.gender,
				},
				photo: profile.photo,
				dob: profile.dob || moment().subtract(18, "years").valueOf(),
			});
		}
	}, [profile]);

	const onSubmit = () => {
		if (!Values.gender?.value) {
			return Toast.error("Please select gender");
		}
		setLoader(true);
		patch(
			apis.onboardUser,
			{
				...Values,
				gender: Values.gender?.value,
			},
			(r, e) => {
				if (r) {
					window.location.reload();
					setLoader();
				} else if (e) {
					Toast.handleError(e);
					setLoader();
				}
			}
		);
	};

	return (
		<>
			<div className="justify-center w-full h-full align-center">
				<div className="w-full text-center">
					<div className="mt-6 text-5xl font-bold">Welcome to Travel Wings</div>
					<div className="mt-3 text-sm">Please confirm your profile details to enable travel booking</div>

					<div className="flex flex-col max-w-sm mx-auto mt-10 text-start">
						<Form onSubmit={onSubmit} loading={Loader}>
							<UploadPhoto className="m-auto mb-8" name={profile.fName} photo={Values.photo} onUpdate={(obj) => setValues((prev) => ({ ...prev, photo: obj.file.url }))} />

							<div className="grid grid-cols-2 mb-4 gap-x-4">
								<DatePicker backDateSelection={true} maxDate={moment().subtract(18, "years").valueOf()} date={Values.dob} setDate={(dob) => setValues((prev) => ({ ...prev, dob }))} label="Date of Birth" />
								<div>
									<label className="block mb-2">Gender</label>
									<ButtonGroup
										className="!max-w-full"
										selected={Values.gender}
										onClick={(gender) => setValues((prev) => ({ ...prev, gender }))}
										options={[
											{
												label: "Male",
												value: "MALE",
											},
											{
												label: "Female",
												value: "FEMALE",
											},
										]}
									/>
								</div>
							</div>
							{/* <div className="grid grid-cols-2 mb-4 gap-x-4">
								<Input required value={Values.phone} onChange={(phone) => setValues((prev) => ({ ...prev, phone }))} placeholder="Phone" label="Phone Number" />
								<Input required value={Values.company} onChange={(company) => setValues((prev) => ({ ...prev, company }))} placeholder="Company" label="Company Name" />
							</div> */}

							<Button variant="primary" loading={Loader} type="submit" className="w-full mt-8">
								Go to your Account <FiArrowRight className="ml-2" />
							</Button>
							<div onClick={logout} className="mt-4 text-sm text-center cursor-pointer hover:underline">
								Sign Out
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}

export default VerifyDetails;
