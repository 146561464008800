import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Img } from "../../../index";
import RequestStatus from "../Components/requestStatus";
import ViewRequest from "../ViewRequest";
import { useState } from "react";
import PlaceholderImage from "../../../../Assets/Images/placeholders/stay.jpg";
import { useClientSettings } from "Hooks/useClientSettings";

function StayRequest({ photo, rating = 0, amount = 0, name = "", address = "", className = "", status, checkIn, checkOut, locality, approvalRequest, adminRequest, id, onReload = () => {}, onClick = () => {} }) {
	const { currency } = useClientSettings();
	const [OpenId, setOpenId] = useState();

	return (
		<>
			<div onClick={() => setOpenId(id)} className={`relative flex flex-col justify-between min-w-[15rem] border hover:shadow-md cursor-pointer rounded-lg shadow-sm overflow-hidden ${className}`}>
				<div className="p-2">
					<Swiper slidesPerView={"auto"} onSlideChange={(i) => {}} modules={[Navigation]} navigation={true} className="rounded-lg swiper-carousel">
						<SwiperSlide>
							<Img placeholder={PlaceholderImage} src={photo} className="w-full aspect-[5/3]" />
						</SwiperSlide>
					</Swiper>

					<div onClick={onClick} className="p-2">
						<div className="mt-2 font-semibold text-ellipsis">{name}</div>
						<div className="text-sm text-secondary text-ellipsis">{address}</div>
						<div className="mt-2 flex-center-between">
							<div className="text-sm">
								<span className="font-semibold">
									{currency} {amount}
								</span>
							</div>
						</div>
					</div>
				</div>
				{status && <RequestStatus {...{ id, status }} />}
			</div>
			<ViewRequest requestId={OpenId} onClose={() => setOpenId()} onReload={onReload} />
		</>
	);
}

export default StayRequest;
