import { Spinner } from "Components";
import { camelCaseToWords } from "./utils";

export const CLASSES = [
	{ label: "Economy", value: "1" },
	{ label: "Premium Economy", value: "2" },
	{ label: "Business", value: "3" },
	{ label: "First Class", value: "4" },
];

export const COUNTRIES = [
	{ label: "Afghanistan", value: "AF", currency: "AFN" },
	{ label: "Albania", value: "AL", currency: "ALL" },
	{ label: "Algeria", value: "DZ", currency: "DZD" },
	{ label: "Andorra", value: "AD", currency: "EUR" },
	{ label: "Angola", value: "AO", currency: "AOA" },
	{ label: "Antigua and Barbuda", value: "AG", currency: "XCD" },
	{ label: "Argentina", value: "AR", currency: "ARS" },
	{ label: "Armenia", value: "AM", currency: "AMD" },
	{ label: "Australia", value: "AU", currency: "AUD" },
	{ label: "Austria", value: "AT", currency: "EUR" },
	{ label: "Azerbaijan", value: "AZ", currency: "AZN" },
	{ label: "Bahamas", value: "BS", currency: "BSD" },
	{ label: "Bahrain", value: "BH", currency: "BHD" },
	{ label: "Bangladesh", value: "BD", currency: "BDT" },
	{ label: "Barbados", value: "BB", currency: "BBD" },
	{ label: "Belarus", value: "BY", currency: "BYN" },
	{ label: "Belgium", value: "BE", currency: "EUR" },
	{ label: "Belize", value: "BZ", currency: "BZD" },
	{ label: "Benin", value: "BJ", currency: "XOF" },
	{ label: "Bhutan", value: "BT", currency: "BTN" },
	{ label: "Bolivia", value: "BO", currency: "BOB" },
	{ label: "Bosnia and Herzegovina", value: "BA", currency: "BAM" },
	{ label: "Botswana", value: "BW", currency: "BWP" },
	{ label: "Brazil", value: "BR", currency: "BRL" },
	{ label: "Brunei", value: "BN", currency: "BND" },
	{ label: "Bulgaria", value: "BG", currency: "BGN" },
	{ label: "Burkina Faso", value: "BF", currency: "XOF" },
	{ label: "Burundi", value: "BI", currency: "BIF" },
	{ label: "Cabo Verde", value: "CV", currency: "CVE" },
	{ label: "Cambodia", value: "KH", currency: "KHR" },
	{ label: "Cameroon", value: "CM", currency: "XAF" },
	{ label: "Canada", value: "CA", currency: "CAD" },
	{ label: "Central African Republic", value: "CF", currency: "XAF" },
	{ label: "Chad", value: "TD", currency: "XAF" },
	{ label: "Chile", value: "CL", currency: "CLP" },
	{ label: "China", value: "CN", currency: "CNY" },
	{ label: "Colombia", value: "CO", currency: "COP" },
	{ label: "Comoros", value: "KM", currency: "KMF" },
	{ label: "Congo, Democratic Republic of the", value: "CD", currency: "CDF" },
	{ label: "Congo, Republic of the", value: "CG", currency: "XAF" },
	{ label: "Costa Rica", value: "CR", currency: "CRC" },
	{ label: "Croatia", value: "HR", currency: "HRK" },
	{ label: "Cuba", value: "CU", currency: "CUP" },
	{ label: "Cyprus", value: "CY", currency: "EUR" },
	{ label: "Czech Republic", value: "CZ", currency: "CZK" },
	{ label: "Denmark", value: "DK", currency: "DKK" },
	{ label: "Djibouti", value: "DJ", currency: "DJF" },
	{ label: "Dominica", value: "DM", currency: "XCD" },
	{ label: "Dominican Republic", value: "DO", currency: "DOP" },
	{ label: "Ecuador", value: "EC", currency: "USD" },
	{ label: "Egypt", value: "EG", currency: "EGP" },
	{ label: "El Salvador", value: "SV", currency: "USD" },
	{ label: "Equatorial Guinea", value: "GQ", currency: "XAF" },
	{ label: "Eritrea", value: "ER", currency: "ERN" },
	{ label: "Estonia", value: "EE", currency: "EUR" },
	{ label: "Eswatini", value: "SZ", currency: "SZL" },
	{ label: "Ethiopia", value: "ET", currency: "ETB" },
	{ label: "Fiji", value: "FJ", currency: "FJD" },
	{ label: "Finland", value: "FI", currency: "EUR" },
	{ label: "France", value: "FR", currency: "EUR" },
	{ label: "Gabon", value: "GA", currency: "XAF" },
	{ label: "Gambia", value: "GM", currency: "GMD" },
	{ label: "Georgia", value: "GE", currency: "GEL" },
	{ label: "Germany", value: "DE", currency: "EUR" },
	{ label: "Ghana", value: "GH", currency: "GHS" },
	{ label: "Greece", value: "GR", currency: "EUR" },
	{ label: "Grenada", value: "GD", currency: "XCD" },
	{ label: "Guatemala", value: "GT", currency: "GTQ" },
	{ label: "Guinea", value: "GN", currency: "GNF" },
	{ label: "Guinea-Bissau", value: "GW", currency: "XOF" },
	{ label: "Guyana", value: "GY", currency: "GYD" },
	{ label: "Haiti", value: "HT", currency: "HTG" },
	{ label: "Honduras", value: "HN", currency: "HNL" },
	{ label: "Hungary", value: "HU", currency: "HUF" },
	{ label: "Iceland", value: "IS", currency: "ISK" },
	{ label: "India", value: "IN", currency: "INR" },
	{ label: "Indonesia", value: "ID", currency: "IDR" },
	{ label: "Iran", value: "IR", currency: "IRR" },
	{ label: "Iraq", value: "IQ", currency: "IQD" },
	{ label: "Ireland", value: "IE", currency: "EUR" },
	{ label: "Israel", value: "IL", currency: "ILS" },
	{ label: "Italy", value: "IT", currency: "EUR" },
	{ label: "Jamaica", value: "JM", currency: "JMD" },
	{ label: "Japan", value: "JP", currency: "JPY" },
	{ label: "Jordan", value: "JO", currency: "JOD" },
	{ label: "Kazakhstan", value: "KZ", currency: "KZT" },
	{ label: "Kenya", value: "KE", currency: "KES" },
	{ label: "Kiribati", value: "KI", currency: "AUD" },
	{ label: "Kuwait", value: "KW", currency: "KWD" },
	{ label: "Kyrgyzstan", value: "KG", currency: "KGS" },
	{ label: "Laos", value: "LA", currency: "LAK" },
	{ label: "Latvia", value: "LV", currency: "EUR" },
	{ label: "Lebanon", value: "LB", currency: "LBP" },
	{ label: "Lesotho", value: "LS", currency: "LSL" },
	{ label: "Liberia", value: "LR", currency: "LRD" },
	{ label: "Libya", value: "LY", currency: "LYD" },
	{ label: "Liechtenstein", value: "LI", currency: "CHF" },
	{ label: "Lithuania", value: "LT", currency: "EUR" },
	{ label: "Luxembourg", value: "LU", currency: "EUR" },
	{ label: "Madagascar", value: "MG", currency: "MGA" },
	{ label: "Malawi", value: "MW", currency: "MWK" },
	{ label: "Malaysia", value: "MY", currency: "MYR" },
	{ label: "Maldives", value: "MV", currency: "MVR" },
	{ label: "Mali", value: "ML", currency: "XOF" },
	{ label: "Malta", value: "MT", currency: "EUR" },
	{ label: "Marshall Islands", value: "MH", currency: "USD" },
	{ label: "Mauritania", value: "MR", currency: "MRU" },
	{ label: "Mauritius", value: "MU", currency: "MUR" },
	{ label: "Mexico", value: "MX", currency: "MXN" },
	{ label: "Micronesia", value: "FM", currency: "USD" },
	{ label: "Moldova", value: "MD", currency: "MDL" },
	{ label: "Monaco", value: "MC", currency: "EUR" },
	{ label: "Mongolia", value: "MN", currency: "MNT" },
	{ label: "Montenegro", value: "ME", currency: "EUR" },
	{ label: "Morocco", value: "MA", currency: "MAD" },
	{ label: "Mozambique", value: "MZ", currency: "MZN" },
	{ label: "Myanmar", value: "MM", currency: "MMK" },
	{ label: "Namibia", value: "NA", currency: "NAD" },
	{ label: "Nauru", value: "NR", currency: "AUD" },
	{ label: "Nepal", value: "NP", currency: "NPR" },
	{ label: "Netherlands", value: "NL", currency: "EUR" },
	{ label: "New Zealand", value: "NZ", currency: "NZD" },
	{ label: "Nicaragua", value: "NI", currency: "NIO" },
	{ label: "Niger", value: "NE", currency: "XOF" },
	{ label: "Nigeria", value: "NG", currency: "NGN" },
	{ label: "North Korea", value: "KP", currency: "KPW" },
	{ label: "North Macedonia", value: "MK", currency: "MKD" },
	{ label: "Norway", value: "NO", currency: "NOK" },
	{ label: "Oman", value: "OM", currency: "OMR" },
	{ label: "Pakistan", value: "PK", currency: "PKR" },
	{ label: "Palau", value: "PW", currency: "USD" },
	{ label: "Panama", value: "PA", currency: "PAB" },
	{ label: "Papua New Guinea", value: "PG", currency: "PGK" },
	{ label: "Paraguay", value: "PY", currency: "PYG" },
	{ label: "Peru", value: "PE", currency: "PEN" },
	{ label: "Philippines", value: "PH", currency: "PHP" },
	{ label: "Poland", value: "PL", currency: "PLN" },
	{ label: "Portugal", value: "PT", currency: "EUR" },
	{ label: "Qatar", value: "QA", currency: "QAR" },
	{ label: "Romania", value: "RO", currency: "RON" },
	{ label: "Russia", value: "RU", currency: "RUB" },
	{ label: "Rwanda", value: "RW", currency: "RWF" },
	{ label: "Saint Kitts and Nevis", value: "KN", currency: "XCD" },
	{ label: "Saint Lucia", value: "LC", currency: "XCD" },
	{ label: "Saint Vincent and the Grenadines", value: "VC", currency: "XCD" },
	{ label: "Samoa", value: "WS", currency: "WST" },
	{ label: "San Marino", value: "SM", currency: "EUR" },
	{ label: "Sao Tome and Principe", value: "ST", currency: "STN" },
	{ label: "Saudi Arabia", value: "SA", currency: "SAR" },
	{ label: "Senegal", value: "SN", currency: "XOF" },
	{ label: "Serbia", value: "RS", currency: "RSD" },
	{ label: "Seychelles", value: "SC", currency: "SCR" },
	{ label: "Sierra Leone", value: "SL", currency: "SLE" },
	{ label: "Singapore", value: "SG", currency: "SGD" },
	{ label: "Slovakia", value: "SK", currency: "EUR" },
	{ label: "Slovenia", value: "SI", currency: "EUR" },
	{ label: "Solomon Islands", value: "SB", currency: "SBD" },
	{ label: "Somalia", value: "SO", currency: "SOS" },
	{ label: "South Africa", value: "ZA", currency: "ZAR" },
	{ label: "South Korea", value: "KR", currency: "KRW" },
	{ label: "South Sudan", value: "SS", currency: "SSP" },
	{ label: "Spain", value: "ES", currency: "EUR" },
	{ label: "Sri Lanka", value: "LK", currency: "LKR" },
	{ label: "Sudan", value: "SD", currency: "SDG" },
	{ label: "Suriname", value: "SR", currency: "SRD" },
	{ label: "Sweden", value: "SE", currency: "SEK" },
	{ label: "Switzerland", value: "CH", currency: "CHF" },
	{ label: "Syria", value: "SY", currency: "SYP" },
	{ label: "Taiwan", value: "TW", currency: "TWD" },
	{ label: "Tajikistan", value: "TJ", currency: "TJS" },
	{ label: "Tanzania", value: "TZ", currency: "TZS" },
	{ label: "Thailand", value: "TH", currency: "THB" },
	{ label: "Timor-Leste", value: "TL", currency: "USD" },
	{ label: "Togo", value: "TG", currency: "XOF" },
	{ label: "Tonga", value: "TO", currency: "TOP" },
	{ label: "Trinidad and Tobago", value: "TT", currency: "TTD" },
	{ label: "Tunisia", value: "TN", currency: "TND" },
	{ label: "Turkey", value: "TR", currency: "TRY" },
	{ label: "Turkmenistan", value: "TM", currency: "TMT" },
	{ label: "Tuvalu", value: "TV", currency: "AUD" },
	{ label: "Uganda", value: "UG", currency: "UGX" },
	{ label: "Ukraine", value: "UA", currency: "UAH" },
	{ label: "United Arab Emirates", value: "AE", currency: "AED" },
	{ label: "United Kingdom", value: "GB", currency: "GBP" },
	{ label: "United States", value: "US", currency: "USD" },
	{ label: "Uruguay", value: "UY", currency: "UYU" },
	{ label: "Uzbekistan", value: "UZ", currency: "UZS" },
	{ label: "Vanuatu", value: "VU", currency: "VUV" },
	{ label: "Vatican City", value: "VA", currency: "EUR" },
	{ label: "Venezuela", value: "VE", currency: "VES" },
	{ label: "Vietnam", value: "VN", currency: "VND" },
	{ label: "Yemen", value: "YE", currency: "YER" },
	{ label: "Zambia", value: "ZM", currency: "ZMW" },
	{ label: "Zimbabwe", value: "ZW", currency: "ZWL" },
];

export const CHECKIN_URLS = [
	{
		label: "Spice Jet",
		value: "SG",
		url: "https://www.spicejet.com/#checkin",
	},
	{
		label: "Indigo",
		value: "6E",
		url: "https://www.goindigo.in/web-check-in.html",
	},
	{
		label: "Vistara",
		value: "UK",
		url: "https://www.airvistara.com/in/en/travel-information/vistara-experience/on-ground/check-in/web-check-in",
	},
	{
		label: "Air India",
		value: "AI",
		url: "https://ota.airindia.in/huf/",
	},
	{
		label: "Air Asia",
		value: "I5",
		url: "https://www.airasia.com/check-in/v2/en/gb",
	},
	{
		label: "Air India Express",
		value: "IX",
		url: "https://www.airindiaexpress.com/checkin-home",
	},
	{
		label: "Akasa Air",
		value: "QP",
		url: "https://www.akasaair.com/check-in/web-check-in",
	},
	{
		label: "Go First",
		value: "G8",
		url: "https://www.flygofirst.com/plan-my-trip/web-check-in/",
	},
];

export const toast = {
	success: {
		icon: "✅",
		style: {
			fontSize: "0.8rem",
		},
	},
	error: {
		style: {
			fontSize: "0.8rem",
		},
	},
	loading: {
		icon: <Spinner size="xs" />,
		style: {
			fontSize: "0.8rem",
		},
	},
};

export const fontSizes = {
	small: "14px",
	medium: "16px",
	large: "18px",
};

export const APPROVERS = [
	"Team",
	{
		label: "Team Head 1",
		value: "TH1",
	},
	{
		label: "Team Head 2",
		value: "TH2",
	},
	"Office",
	{
		label: "Office Head 1",
		value: "OH1",
	},
	{
		label: "Office Head 2",
		value: "OH2",
	},
];

export const getWhenConditions = (type) => {
	return [
		{
			value: "AMOUNT",
			label: `${camelCaseToWords(type)} amount`,
		},
		{
			value: "IN_POLICY",
			label: `${camelCaseToWords(type)} is in Policy`,
		},
		{
			value: "OUT_POLICY",
			label: `${camelCaseToWords(type)} is out of Policy`,
		},
	];
};

export const APPROVER_CODE_FIELD_MAP = {
	TH1: {
		type: "team",
		field: "head1Id",
		association: "head1",
		label: "Team Head 1",
	},
	TH2: {
		type: "team",
		field: "head2Id",
		association: "head2",
		label: "Team Head 2",
	},
	OH1: {
		type: "office",
		field: "head1Id",
		association: "head1",
		label: "Office Head 1",
	},
	OH2: {
		type: "office",
		field: "head2Id",
		association: "head2",
		label: "Office Head 2",
	},
};
