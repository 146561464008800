import React, { useState } from "react";
import { StampTo24hr } from "../../../../../../../Utils/utils";
import { FaPlane } from "react-icons/fa";
import { ViewBooking, BookingStatus } from "../../../../../../../Components";
import { useClientSettings } from "Hooks/useClientSettings";

function FlightBooking({ from, to, departure, arrival, amount, airline, date, mode, type, id, status }) {
	const { currency } = useClientSettings();
	const [OpenId, setOpenId] = useState();

	return (
		<>
			<div onClick={() => setOpenId(true)} className="overflow-hidden border shadow-sm cursor-pointer rounded-xl bg-canvas min-w-min hover:shadow-md">
				<div className="p-4">
					<div className="flex-center-between">
						<div className="align-center">
							<img src={airline.logo} alt={airline.name} className="w-6 h-6 mr-2 rounded-full" />
							<div className="text-xs text-secondary whitespace-nowrap">
								{airline.name} {airline.code}-{airline.number}
							</div>
						</div>
						<div className="text-xs font-bold">
							{currency} {amount}
						</div>
					</div>
					<div className="flex mt-3 gap-x-4">
						<div>
							<div className="font-bold whitespace-nowrap">{StampTo24hr(departure.time)}</div>
							<div className="text-xs text-secondary">{from.code}</div>
						</div>
						<div className="flex-grow w-24 flex-center">
							<div className="w-full">
								<div className="align-center">
									<div className="w-2 h-2 border rounded-full border-dark" />
									<div className="flex-grow border-t border-dotted border-dark" />
									<FaPlane />
									<div className="flex-grow border-t border-dotted border-dark" />
									<div className="w-2 h-2 border rounded-full border-dark" />
								</div>
								<div className="mt-2 text-xs text-center whitespace-nowrap">2hr 30min</div>
							</div>
						</div>
						<div>
							<div className="font-bold whitespace-nowrap">{StampTo24hr(arrival.time)}</div>
							<div className="text-xs text-secondary">{to.code}</div>
						</div>
					</div>
				</div>
				{status && <BookingStatus status={status} id={id} />}
			</div>
			<ViewBooking id={OpenId} type="flight" onClose={() => setOpenId()} />
		</>
	);
}

export default FlightBooking;
