import React, { useEffect, useState } from "react";
import { RangeInput, Checkbox, Button } from "../../..";
import { FiFilter } from "react-icons/fi";
import ViewYourPolicy from "../../../ViewYourPolicy";

function Flightfilters({ currency, flights = [], filters = {}, updateFilters = () => {} }) {
	const [Airlines, setAirlines] = useState([]);
	const [MaxRange, setMaxRange] = useState(0);

	useEffect(() => {
		const airlines = [];
		let maxPrice = 0;
		flights.forEach((flight) => {
			flight.fares.forEach((fare) => {
				if (fare?.price > maxPrice) {
					maxPrice = fare?.price;
				}
			});
			flight.segments.forEach((segment) => {
				if (!airlines.includes(segment.airlineName)) {
					airlines.push(segment.airlineName);
				}
			});
		});
		setAirlines(airlines);
		setMaxRange(maxPrice + 500);
		updateFilters({
			...filters,
			maxPrice,
		});
	}, [flights.onwardFlights]);

	const applyFilter = (type, checked, value) => {
		if (checked) {
			updateFilters({
				...filters,
				[type]: [...filters[type], value],
			});
		} else {
			updateFilters({
				...filters,
				[type]: filters[type].filter((a) => a !== value),
			});
		}
	};

	return (
		<div className="flex flex-col justify-between h-full">
			<div>
				<ViewYourPolicy />
				<div className="mt-6 align-center">
					<FiFilter className="mr-2" />
					<h5>Flight filters</h5>
				</div>

				<div className="mt-6">
					<div className="mb-2 text-sm font-semibold">Stops</div>
					<div>
						<Checkbox checked={filters.stops.includes(0)} onChange={(checked) => applyFilter("stops", checked, 0)} label="Non-Stop" className="mt-2" />
						<Checkbox checked={filters.stops.includes(1)} onChange={(checked) => applyFilter("stops", checked, 1)} label="1 Stop" className="mt-2" />
						<Checkbox checked={filters.stops.includes(2)} onChange={(checked) => applyFilter("stops", checked, 2)} label="2 stop" className="mt-2" />
					</div>
				</div>

				<div className="mt-6">
					<div className="mb-2 text-sm font-semibold">Quick filters</div>
					<div>
						<Checkbox checked={filters.inPolicy} onChange={(checked) => updateFilters((prev) => ({ ...prev, inPolicy: checked }))} label="Fares that are in my policy" className="mt-2" />
						<Checkbox checked={filters.fullyRefundable} onChange={(checked) => updateFilters((prev) => ({ ...prev, fullyRefundable: checked }))} label="Fully refundable fares" className="mt-2" />
					</div>
				</div>

				<div className="mt-6">
					<div className="text-sm font-semibold flex-center-between">
						<div className="mb-2">Price</div>
						<div className="font-bold text-dark">
							{currency} {filters.maxPrice}
						</div>
					</div>
					<RangeInput max={MaxRange} value={filters.maxPrice} onChange={(value) => updateFilters({ ...filters, maxPrice: value })} unit={currency} />
				</div>

				<div className="mt-6">
					<div className="mb-2 text-sm font-semibold">Airlines</div>
					<div>
						{Airlines.map((airline, i) => (
							<Checkbox checked={filters.airlines.includes(airline)} onChange={(checked) => applyFilter("airlines", checked, airline)} key={i} label={airline} className="mt-2" />
						))}
					</div>
				</div>

				<div className="mt-6">
					<div className="mb-2 text-sm font-semibold">Departure Time</div>
					<div>
						<div className="mt-2 flex-center-between">
							<Checkbox checked={filters.time.includes("early")} onChange={(checked) => applyFilter("time", checked, "early")} label="Early Morning" />
							<div className="text-xs text-secondary">Midnight - 8AM</div>
						</div>
						<div className="mt-2 flex-center-between">
							<Checkbox checked={filters.time.includes("morning")} onChange={(checked) => applyFilter("time", checked, "morning")} label="Morning" />
							<div className="text-xs text-secondary">8AM - Noon</div>
						</div>
						<div className="mt-2 flex-center-between">
							<Checkbox checked={filters.time.includes("noon")} onChange={(checked) => applyFilter("time", checked, "noon")} label="Afternoon" />
							<div className="text-xs text-secondary">Noon - 4PM</div>
						</div>
						<div className="mt-2 flex-center-between">
							<Checkbox checked={filters.time.includes("evening")} onChange={(checked) => applyFilter("time", checked, "evening")} label="Evening" />
							<div className="text-xs text-secondary">4PM - 8PM</div>
						</div>
						<div className="mt-2 flex-center-between">
							<Checkbox checked={filters.time.includes("night")} onChange={(checked) => applyFilter("time", checked, "night")} label="Night" />
							<div className="text-xs text-secondary">8PM - Midnight</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-4">
				<Button
					onClick={() =>
						updateFilters({
							stops: [],
							airlines: [],
							time: [],
							inPolicy: false,
							fullyRefundable: false,
							maxPrice: MaxRange,
						})
					}
					variant="dark"
					className="w-full mt-4 btn-sm"
				>
					Reset filters
				</Button>
			</div>
		</div>
	);
}

export default Flightfilters;
