import React, { useEffect, useState } from "react";
import { AnimatedTick, Button, Receipt, ReceiptStoreCode, SidePaneContent, Toast } from "../../../../../../Components";
import { RiDragDropLine } from "react-icons/ri";
import { useClientSettings } from "../../../../../../Hooks/useClientSettings";
import ReceiptDrop from "./drop";
import { apis, post } from "../../../../../../Utils/axios";

function SubmitReceipt({ dragging, loading, onReload = () => {} }) {
	const { isEnabled, currency } = useClientSettings();
	const expenseEnabled = isEnabled("ORGANIZATION") && isEnabled("EXPENSE");

	const [Success, setSuccess] = useState(false);
	const [Loading, setLoading] = useState(false);
	const [SelectedReceipts, setSelectedReceipts] = useState([]);
	const totalAmount = SelectedReceipts.reduce((acc, receipt) => acc + receipt.amount, 0);

	useEffect(() => {
		setSelectedReceipts([]);
	}, [loading]);

	const onReceiptDrop = () => {
		const updatedSelection = SelectedReceipts.filter((receipt) => receipt.id !== dragging.id);
		setSelectedReceipts([...updatedSelection, dragging]);
	};

	if (!expenseEnabled) {
		//If expense is not enabled do not show reimbursement side pane
		return null;
	}

	const removeSelection = (id) => {
		setSelectedReceipts(SelectedReceipts.filter((receipt) => receipt.id !== id));
	};

	const onSubmit = () => {
		setLoading(true);
		post(apis.submitReceipts, { receipts: SelectedReceipts.map((r) => r.id) }, (r, e) => {
			if (r) {
				setSelectedReceipts([]);
				setLoading(false);
				setSuccess(true);
				setTimeout(() => {
					setSuccess(false);
				}, 3000);
				onReload();
			} else if (e) {
				setLoading(false);
				Toast.handleError(e);
			}
		});
	};

	if (SelectedReceipts.length) {
		return (
			<SidePaneContent>
				<div className={`w-full h-full flex flex-col justify-between bg-light relative`}>
					<div onDragOver={(e) => e.preventDefault()} onDrop={onReceiptDrop} className="flex-grow w-full overflow-y-auto">
						<div className="sticky top-0 p-2 bg-light">
							<h4>{SelectedReceipts.length} Selected</h4>
						</div>
						<div className="flex flex-col gap-2 p-2">
							{SelectedReceipts.map((receipt, i) => {
								return <Receipt key={i} {...receipt} onDelete={() => removeSelection(receipt.id)} />;
							})}
						</div>
					</div>
					<div className="flex flex-col gap-2 p-2">
						<div className="p-2 text-white rounded-lg bg-dark">
							<h1 className="mb-2 text-xl">
								Total {currency} {totalAmount}
							</h1>
							<Button loading={Loading} onClick={onSubmit} variant="primary" className="w-full">
								Send for Reimbursement
							</Button>
						</div>
					</div>
					<ReceiptDrop dragging={dragging} onDrop={onReceiptDrop} />
				</div>
			</SidePaneContent>
		);
	}

	return (
		<>
			<SidePaneContent>
				<div className="relative flex flex-col w-full h-full gap-2 p-2">
					<div onDragOver={(e) => e.preventDefault()} onDrop={onReceiptDrop} className="flex-grow w-full">
						{Success ? (
							<div className="w-full h-full flex-center">
								<div>
									<AnimatedTick />
									<h3 className="mt-6">Receipts Submitted</h3>
								</div>
							</div>
						) : (
							<div className={`w-full h-full transition-all rounded-2xl flex-center p-6 text-sm`}>
								<div className="text-center ">
									<RiDragDropLine size={52} className="m-auto mb-6" />
									Drag & Drop receipts you want to get reimbursed
								</div>
							</div>
						)}
					</div>
					<div className="flex flex-col gap-2">
						{/* <div className="flex flex-col justify-between p-4 border bg-light rounded-xl">
							<h1 className="text-3xl">{currency} 0</h1>
							<div className="mt-2 text-sm">Expense not submitted</div>
						</div> */}
						<div className="p-4 text-xs text-center">
							<ReceiptStoreCode className="m-auto mb-4" />
							Scan to upload photos to receipt store using your phone
						</div>
					</div>
					<ReceiptDrop dragging={dragging} onDrop={onReceiptDrop} />
				</div>
			</SidePaneContent>
		</>
	);
}

export default SubmitReceipt;
