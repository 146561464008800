import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { apis, get } from "../../../../../../../../Utils/axios";
import FlightCard from "./flightCard";
import FareCard from "./fareCard";

function UserChoice() {
	const [searchParams] = useSearchParams();
	const requestId = searchParams.get("requestId") || "";

	const [UserChoice, setUserChoice] = useState();

	useEffect(() => {
		if (requestId) {
			get(apis.getRequestDetails, { requestId }, (r, e) => {
				if (r?.choices) {
					setUserChoice(r.choices[0]);
				}
			});
		}
	}, [requestId]);

	if (UserChoice) {
		const { flight, fare, type } = UserChoice;
		return (
			<div className="sticky top-0 z-10 p-2 mt-4 rounded-lg shadow-sm bg-accent fade-in-down">
				<div className="px-2 mb-2 text-white flex-center-between">
					<h4>User's Choice</h4>
					<div className="text-xs">Price of this flight may have changed since you received the request</div>
				</div>
				<div className="grid grid-cols-3 rounded-md bg-canvas">
					<FlightCard segments={flight?.segments} duration={flight?.duration} />
					<div className="flex gap-x-2">
						<FareCard fare={fare} />
					</div>
				</div>
			</div>
		);
	}

	return null;
}

export default UserChoice;
